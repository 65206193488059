export default {
    namespaced: true,
    state: {
        user_to: null,
        user_to_room: null,
        my_room: 'videocall_*',
        loading: false,
        videocall_wait_modal: false,
        videocall_from_modal: false,
        data_from_modal: null,
        videocall_modal: false,
        videocall_on: false,
        go_video_call: false,
        peer_type: null
    },
    mutations: {
        setUserTo: (state, payload) => state.user_to = payload,
        setUserToRoom: (state, payload) => state.user_to_room = payload,
        setMyRoom: (state, payload) => state.my_room = payload,
        setLoading: (state, payload) => state.loading = payload,
        setVideoCallWaitModal: (state, payload) => state.videocall_wait_modal = payload,
        setVideoCallFromModal: (state, payload) => state.videocall_from_modal = payload,
        setDataFromModal: (state, payload) => state.data_from_modal = payload,
        setVideoCallModal: (state, payload) => state.videocall_modal = payload,
        setVideoCallOn: (state, payload) => state.videocall_on = payload,
        setGoVideoCall: (state, payload) => state.go_video_call = payload,
        setPeerType: (state, payload) => state.peer_type = payload
    },
    getters: {
        getUserTo: state => state.user_to,
        getUserToRoom: state => state.user_to_room,
        getMyRoom: state => state.my_room,
        getLoading: state => state.loading,
        getVideoCallWaitModal: state => state.videocall_wait_modal,
        getVideoCallFromModal: state => state.videocall_from_modal,
        getDataFromModal: state => state.data_from_modal,
        getVideoCallModal: state => state.videocall_modal,
        getVideoCallOn: state => state.videocall_on,
        getGoVideoCall: state => state.go_video_call,
        getPeerType: state => state.peer_type
    },
    actions: {
        requestVideoCallToUser: ({ commit, getters }, user_to) => {
            if (getters.getVideoCallOn) { return false }
            if (getters.getVideoCallModal) { return false }
            if (getters.getVideoCallWaitModal) { return false }
            if (getters.getVideoCallFromModal) { return false }
            commit('setUserTo', user_to)
            commit('setUserToRoom', 'videocall_' + user_to.id)
            commit('setVideoCallWaitModal', true)
            commit('setPeerType', 'caller')
            commit('setGoVideoCall', true)
        },
        acceptVideoCallFromUser: ({ commit, getters }, user_from) => {
            if (getters.getVideoCallOn) { return false }
            if (getters.getVideoCallModal) { return false }
            if (getters.getVideoCallWaitModal) { return false }
            commit('setUserTo', user_from)
            commit('setUserToRoom', 'videocall_' + user_from.id)
            commit('setVideoCallFromModal', false)
            commit('setVideoCallModal', true)
            commit('setPeerType', 'client')
            commit('setVideoCallOn', true)
        },
        cleanData: ({ commit }) => {
            commit('setUserTo', null)
            commit('setUserToRoom', null)
            commit('setVideoCallWaitModal', false)
            commit('setVideoCallFromModal', false)
            commit('setDataFromModal', null)
            commit('setVideoCallModal', false)
            commit('setVideoCallOn', false)
            commit('setGoVideoCall', false)
            commit('setPeerType', null)
        }
    }
}
