import { omit, findIndex, reduce, map, keys } from "lodash-es";

const defaultAccount = {
    name: "",
    lastname: "",
    email: "",
    gender: "",
    language: "",
    id_profile: "",
    postal_code: "",
};

const defaultQuestion = {
    security_question: "",
    security_answer: "",
};

const defaultPassword = {
    old_password: "",
    password: "",
    password_confirmation: "",
};

const defaultNotifications = {
    mail_gift_received: true,
    mail_favorite_login: true,
    mail_profile_visit: true,
    mail_user_area_login: true,
    mail_offers: true,
};

const defaultContact = {
    subject: "",
    message: "",
    sent: false,
};

export default {
    namespaced: true,
    state: {
        account: defaultAccount,
        question: defaultQuestion,
        loading: false,
        blocked: [],
        blocked_pagination: null,
        blocked_loading: false,
        hide: false,
        password: defaultPassword,
        notifications: defaultNotifications,
        contact: defaultContact,
        chat_messages: [],
        chat_pagination: null,
        chat_textbox: "",
    },
    mutations: {
        // Account ======================
        setAccount: (state, payload) => {
            state.account = {
                name: payload.user.name,
                lastname: payload.user.lastname,
                email: payload.user.email,
                gender: payload.user.gender,
                active: payload.user.active,
                language: payload.configuration.language,
                id_profile: payload.configuration.id_profile,
                postal_code: payload.configuration.postal_code,
            };
        },
        setAccountName: (state, payload) => (state.account.name = payload),
        setAccountLastName: (state, payload) =>
            (state.account.lastname = payload),
        setAccountEmail: (state, payload) => (state.account.email = payload),
        setAccountGender: (state, payload) => (state.account.gender = payload),
        setAccountLanguage: (state, payload) =>
            (state.account.language = payload),
        setAccountIdProfile: (state, payload) =>
            (state.account.id_profile = payload),
        setAccountPostalCode: (state, payload) =>
            (state.account.postal_code = payload),
        setAccountActive: (state, payload) => (state.account.active = payload),
        // Question =======================
        setQuestion: (state, payload) => {
            state.question = {
                security_question: payload.security_question,
                security_answer: payload.security_answer,
            };
        },
        setSecurityQuestion: (state, payload) =>
            (state.question.security_question = payload),
        setSecurityAnswer: (state, payload) =>
            (state.question.security_answer = payload),
        // Blocked ==========================
        setBlocked: (state, payload) => (state.blocked = payload),
        setBlockedPagination: (state, payload) =>
            (state.blocked_pagination = omit(payload, ["data"])),
        setBlockedLoading: (state, payload) =>
            (state.blocked_loading = payload),
        removeBlocked: (state, payload) => {
            let index = findIndex(state.blocked, (o) => o.id == payload);
            if (index != -1) {
                state.blocked.splice(index, 1);
            }
        },
        // Hide =============================
        setHide: (state, payload) => (state.hide = payload),
        // Password =========================
        setOldPassword: (state, payload) =>
            (state.password.old_password = payload),
        setPassword: (state, payload) => (state.password.password = payload),
        setPasswordConfirmation: (state, payload) =>
            (state.password.password_confirmation = payload),
        cleanPassword: (state) => (state.password = defaultPassword),
        // Notifications ====================
        setNotifications: (state, payload) => {
            state.notifications = {
                mail_gift_received: payload.mail_gift_received,
                mail_favorite_login: payload.mail_favorite_login,
                mail_profile_visit: payload.mail_profile_visit,
                mail_user_area_login: payload.mail_user_area_login,
                mail_offers: payload.mail_offers,
            };
        },
        setNotificationMailGiftReceived: (state, payload) =>
            (state.notifications.mail_gift_received = payload),
        setNotificationFavoriteLogin: (state, payload) =>
            (state.notifications.mail_favorite_login = payload),
        setNotificationMailProfileVisit: (state, payload) =>
            (state.notifications.mail_profile_visit = payload),
        setNotificationMailUserAreaLogin: (state, payload) =>
            (state.notifications.mail_user_area_login = payload),
        setNotificationMailOffers: (state, payload) =>
            (state.notifications.mail_offers = payload),
        // Contact ===========================
        setContact: (state, payload) => (state.contact = payload),
        setContactSubject: (state, payload) =>
            (state.contact.subject = payload),
        setContactMessage: (state, payload) =>
            (state.contact.message = payload),
        setContactSent: (state, payload) => (state.contact.sent = payload),
        setChatMessages: (state, payload) =>
            (state.chat_messages = state.chat_messages.concat(
                payload.reverse()
            )),
        setChatPagination: (state, payload) =>
            (state.chat_pagination = omit(payload, ["data"])),
        addChatMessage: (state, payload) => state.chat_messages.push(payload),
        setChatTextBox: (state, payload) => (state.chat_textbox = payload),
        cleanChatMessages: (state) => (state.chat_messages = []),
        // Other =============================
        setLoading: (state, payload) => (state.loading = payload),
    },
    getters: {
        // Account ======================================
        getAccount: (state) => state.account,
        getAccountName: (state) => state.account.name,
        getAccountLastName: (state) => state.account.lastname,
        getAccountEmail: (state) => state.account.email,
        getAccountGender: (state) => state.account.gender,
        getAccountLanguage: (state) => state.account.language,
        getAccountIdProfile: (state) => state.account.id_profile,
        getAccountPostalCode: (state) => state.account.postal_code,
        getAccountActive: (state) => state.account.active,
        // Question ======================================
        getQuestion: (state) => state.question,
        getSecurityQuestion: (state) => state.question.security_question,
        getSecurityAnswer: (state) => state.question.security_answer,
        // Blocked =======================================
        getBlocked: (state) => state.blocked,
        getBlockedPagination: (state) => state.blocked_pagination,
        getBlockedLoading: (state) => state.blocked_loading,
        // Hide ==========================================
        getHide: (state) => state.hide,
        // Password ======================================
        getAllPassword: (state) => state.password,
        getOldPassword: (state) => state.password.old_password,
        getPassword: (state) => state.password.password,
        getPasswordConfirmation: (state) =>
            state.password.password_confirmation,
        // Notifications =================================
        getNotifications: (state) => state.notifications,
        getNotificationMailGiftReceived: (state) =>
            state.notifications.mail_gift_received,
        getNotificationFavoriteLogin: (state) =>
            state.notifications.mail_favorite_login,
        getNotificationMailProfileVisit: (state) =>
            state.notifications.mail_profile_visit,
        getNotificationMailUserAreaLogin: (state) =>
            state.notifications.mail_user_area_login,
        getNotificationMailOffers: (state) => state.notifications.mail_offers,
        // Contact =======================================
        getContact: (state) => state.contact,
        getContactSubject: (state) => state.contact.subject,
        getContactMessage: (state) => state.contact.message,
        getContactSent: (state) => state.contact.sent,
        getChatMessages: (state) => state.chat_messages,
        getChatPagination: (state) => state.chat_pagination,
        getChatTextBox: (state) => state.chat_textbox,
        getChatMessagesGroupedByDay: (state) => {
            const groups = reduce(
                state.chat_messages,
                (result, message) => {
                    const date = message.createdAt.split("T")[0];
                    if (!result[date]) {
                        result[date] = [];
                    }
                    result[date].push(message);
                    return result;
                },
                {}
            );

            return map(keys(groups), (date) => {
                return {
                    date,
                    messages: groups[date],
                };
            });
        },
        // Other =========================================
        getLoading: (state) => state.loading,
    },
    actions: {
        getAccount: ({ commit }) => {
            commit("setLoading", true);
            return window.axios
                .get("/settings/account/get")
                .then((response) => {
                    commit("setAccount", response.data);
                    commit("setLoading", false);
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                    commit("setLoading", false);
                    throw error;
                });
        },
        updateAccount: ({ commit, getters }) => {
            commit("setLoading", true);
            return window.axios
                .post("/settings/account/update", getters.getAccount)
                .then((response) => {
                    commit("setAccount", response.data);
                    commit("setLoading", false);
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                    commit("setLoading", false);
                    throw error;
                });
        },
        changePassword: ({ commit, getters }) => {
            commit("setLoading", true);
            return window.axios
                .post("/settings/password/change", getters.getAllPassword)
                .then((response) => {
                    commit("cleanPassword");
                    commit("setLoading", false);
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                    commit("setLoading", false);
                    throw error;
                });
        },
        getQuestion: ({ commit }) => {
            commit("setLoading", true);
            return window.axios
                .get("/settings/question/get")
                .then((response) => {
                    commit("setQuestion", response.data);
                    commit("setLoading", false);
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                    commit("setLoading", false);
                    throw error;
                });
        },
        updateQuestion: ({ commit, getters }) => {
            commit("setLoading", true);
            return window.axios
                .post("/settings/question/update", getters.getQuestion)
                .then((response) => {
                    commit("setQuestion", response.data);
                    commit("setLoading", false);
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                    commit("setLoading", false);
                    throw error;
                });
        },
        getBlocked: ({ commit }, url) => {
            commit("setBlockedLoading", true);
            let route = "settings/blocked/get";
            if (url != null) {
                route = url;
            }
            return window.axios
                .get(route)
                .then((response) => {
                    commit("setBlocked", response.data.data);
                    commit("setBlockedPagination", response.data);
                    commit("setBlockedLoading", false);
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                    commit("setBlockedLoading", false);
                    throw error;
                });
        },
        toggleBlocked: ({ commit }, id_user_blocked) => {
            commit("setBlockedLoading", true);
            return window.axios
                .post("/settings/blocked/toggle", {
                    id_user_blocked: id_user_blocked,
                })
                .then((response) => {
                    commit("removeBlocked", response.data.blocked.id);
                    commit("setBlockedLoading", false);
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                    commit("setBlockedLoading", false);
                    throw error;
                });
        },
        getHide: ({ commit }) => {
            commit("setLoading", true);
            return window.axios
                .get("settings/hide/get")
                .then((response) => {
                    commit("setHide", response.data);
                    commit("setLoading", false);
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                    commit("setLoading", false);
                    throw error;
                });
        },
        hideToggle: ({ commit }) => {
            commit("setLoading", true);
            return window.axios
                .post("/settings/hide/toggle")
                .then((response) => {
                    commit("setHide", response.data);
                    commit("setLoading", false);
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                    commit("setLoading", false);
                    throw error;
                });
        },
        getNotifications: ({ commit }) => {
            commit("setLoading", true);
            return window.axios
                .get("/settings/notifications/get")
                .then((response) => {
                    commit("setNotifications", response.data);
                    commit("setLoading", false);
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                    commit("setLoading", false);
                    throw error;
                });
        },
        updateNotifications: ({ commit, getters }) => {
            commit("setLoading", true);
            return window.axios
                .post(
                    "/settings/notifications/update",
                    getters.getNotifications
                )
                .then((response) => {
                    commit("setNotifications", response.data);
                    commit("setLoading", false);
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                    commit("setLoading", false);
                    throw error;
                });
        },
        sendContactMail: ({ commit, getters }) => {
            commit("setLoading", true);
            return window.axios
                .post("/support/contact/mail", getters.getContact)
                .then((response) => {
                    commit("setContactSubject", "");
                    commit("setContactMessage", "");
                    commit("setContactSent", true);
                    commit("setLoading", false);
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                    commit("setLoading", false);
                    throw error;
                });
        },
        getChatMessages: ({ commit }, url) => {
            commit("setLoading", true);
            let route = "/support/chat/get";
            if (url != null) {
                route = url;
            }
            return window.axios
                .get(route)
                .then((response) => {
                    commit("setChatMessages", response.data.data);
                    commit("setChatPagination", response.data);
                    commit("setLoading", false);
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                    commit("setLoading", false);
                    throw error;
                });
        },
        sendChatMessage: ({ commit, getters }) => {
            commit("setLoading", true);
            return window.axios
                .post("/support/chat/send", {
                    message: getters.getChatTextBox,
                })
                .then((response) => {
                    commit("addChatMessage", response.data);
                    commit("setLoading", false);
                    commit("setChatTextBox", "");
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                    commit("setLoading", false);
                    throw error;
                });
        },
    },
};
