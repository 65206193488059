const BaseModule = () => import('../../components/basemodule.vue')
const Terms = () => import('./terms.vue')
const Privacy = () => import('./privacy.vue')

export default [
    {
        path: '/legal',
        component: BaseModule,
        name: 'Legal',
        redirect: '/legal/terms',
        children: [
            {
                name: 'Terms',
                path: 'terms',
                component: Terms
            },
            {
                name: 'Privacy',
                path: 'privacy',
                component: Privacy
            }
        ]
    }
]
