import { findIndex, omit, includes, some, isEmpty, forEach } from "lodash-es"

function defaultUser() {
    return {
        id: '',
        name: '',
        lastname: '',
        email: '',
        phone: '',
        avatar: '',
        birthday: '',
        gender: '',
        created_at: '',
        type: '',
        last_membership: null
    }
}

function defaultInformation() {
    return {
        biography: '',
        relation: '',
        sexual_orientation: '',
        body_type: '',
        i_live_with: '',
        eyes_color: '',
        hair_color: '',
        drink: '',
        tobacco: ''
    }
}

function defaultGeo() { return { geo_lat: 0, geo_lon: 0 } }
function defaultSearchingFor() { return { search_for: '', search_limits: '' } }

export default {
    namespaced: true,
    state: {
        user: defaultUser(),
        user_loading: false,
        photos: [],
        photos_pagination: null,
        photos_loading: false,
        selected_photo: null,
        gifts: [],
        gifts_pagination: null,
        sent_gifts: [],
        sent_gifts_pagination: null,
        gifts_loading: false,
        interests: [],
        random_popular_interests: [],
        interests_loading: false,
        searching_for: defaultSearchingFor(),
        searching_for_loading: false,
        information: defaultInformation(),
        information_loading: false,
        geo: defaultGeo(),
        geo_loading: false,
        modules_loaded: [],
        myphoto: null,
        myphoto_loading: false,
        myphoto_comments: [],
        myphoto_comments_pagination: null,
        myphoto_comment: '',
        myphoto_comments_loading: false,
        countryId: '',
    },
    mutations: {
        // User ==============================================
        setUser: (state, payload) => state.user = payload,
        setId: (state, payload) => state.user.id = payload,
        setName: (state, payload) => state.user.name = payload,
        setLastname: (state, payload) => state.user.lastname = payload,
        setEmail: (state, payload) => state.user.email = payload,
        setPhone: (state, payload) => state.user.phone = payload,
        setCreatedAt: (state, payload) => state.user.phone = payload,
        setAge: (state, payload) => state.user.age = payload,
        setAvatar: (state, payload) => state.user.avatar = payload,
        setBirthday: (state, payload) => state.user.birthday = payload,
        setGender: (state, payload) => state.user.gender = payload,
        setUserLoading: (state, payload) => state.user_loading = payload,
        // Photos ==============================================
        setPhotos: (state, payload) => state.photos = payload,
        setPhotosPagination: (state, payload) => state.photos_pagination = omit(payload, ['data']),
        addPhoto: (state, payload) => state.photos.unshift(payload),
        removePhoto: (state, payload) => {
            let index = findIndex(state.photos, o => o.id == payload.id)
            if (index != -1) { state.photos.splice(index, 1) }
        },
        setPhotosLoading: (state, payload) => state.photos_loading = payload,
        setSelectedPhoto: (state, payload) => state.selected_photo = payload,
        // Gifts ==================================================
        setGifts: (state, payload) => state.gifts = payload,
        setGiftsPagination: (state, payload) => state.gifts_pagination = omit(payload, ['data']),
        setSentGifts: (state, payload) => state.sent_gifts = payload,
        setSentGiftsPagination: (state, payload) => state.sent_gifts_pagination = omit(payload, ['data']),
        setGiftsLoading: (state, payload) => state.gifts_loading = payload,
        // Interests ==============================================
        setInterests: (state, payload) => state.interests = payload,
        addInterest: (state, payload) => state.interests.push(payload),
        removeInterest: (state, payload) => {
            let index = findIndex(state.interests, o => o.id == payload.id)
            if (index != -1) { state.interests.splice(index, 1) }
        },
        removeGift: (state, payload) => {
            let index = findIndex(state.gifts, o => o.id == payload.id)
            if (index != -1) { state.gifts.splice(index, 1) }
        },
        removeSentGift: (state, payload) => {
            let index = findIndex(state.sent_gifts, o => o.id == payload.id)
            if (index != -1) { state.sent_gifts.splice(index, 1) }
        },
        setRandomPopularInterests: (state, payload) => state.random_popular_interests = payload,
        setInterestsLoading: (state, payload) => state.interests_loading = payload,
        // Search For ==============================================
        setSearchingFor: (state, payload) => {
            state.searching_for.search_for = payload.search_for
            state.searching_for.search_limits = payload.search_limits
        },
        setSearchFor: (state, payload) => state.searching_for.search_for = payload,
        setSearchLimits: (state, payload) => state.searching_for.search_limits = payload,
        setSearchingForLoading: (state, payload) => state.searching_for_loading = payload,
        // Information ==============================================
        setInformation: (state, payload) => state.information = payload,
        setBiography: (state, payload) => state.information.biography = payload,
        setRelation: (state, payload) => state.information.relation = payload,
        setSexualOrientation: (state, payload) => state.information.sexual_orientation = payload,
        setBodyType: (state, payload) => state.information.body_type = payload,
        setILiveWith: (state, payload) => state.information.i_live_with = payload,
        setEyesColor: (state, payload) => state.information.eyes_color = payload,
        setHairColor: (state, payload) => state.information.hair_color = payload,
        setDrink: (state, payload) => state.information.drink = payload,
        setTobacco: (state, payload) => state.information.tobacco = payload,
        setInformationLoading: (state, payload) => state.information_loading = payload,
        cleanInformationFields: state => state.information = defaultInformation(),
        // Geo ======================================================
        setGeo: (state, payload) => {
            state.geo.geo_lat = payload.geo_lat
            state.geo.geo_lon = payload.geo_lon
        },
        setLat: (state, payload) => state.geo.geo_lat = payload,
        setLon: (state, payload) => state.geo.geo_lon = payload,
        setGeoLoading: (state, payload) => state.geo_loading = payload,
        // Module Loaded ============================================
        setModuleLoaded: (state, payload) => state.modules_loaded = payload,
        addModuleLoaded: (state, payload) => {
            if (!includes(state.modules_loaded, payload)) {
                state.modules_loaded.push(payload)
            }
        },
        // My Photo =================================================
        setMyPhoto: (state, payload) => state.myphoto = payload,
        setMyPhotoLoading: (state, payload) => state.myphoto_loading = payload,
        setMyPhotoComments: (state, payload) => forEach(payload, p => { state.myphoto_comments.unshift(p) }),
        setMyPhotoCommentsPagination: (state, payload) => state.myphoto_comments_pagination = payload,
        setMyPhotoComment: (state, payload) => state.myphoto_comment = payload,
        setMyPhotoCommentsLoading: (state, payload) => state.myphoto_comments_loading = payload,
        addMyPhotoComment: (state, payload) => state.myphoto_comments.push(payload),
        deleteMyPhotoComment: (state, payload) => {
            let index = findIndex(state.myphoto_comments, o => o.id == payload.id)
            if (index != -1) { state.myphoto_comments.splice(index, 1) }
        },
        myPhotoClean: state => {
            state.myphoto = null
            state.myphoto_comments = []
            state.myphoto_comments_pagination = null
            state.myphoto_comment = ''
        },
        setCountry: (state, payload) => state.country = payload,
        setCountryId: (state, payload) => state.countryId = payload
    },
    getters: {
        // User ==============================================
        getUser: state => state.user,
        getId: state => state.user.id,
        getName: state => state.user.name,
        getLastname: state => state.user.lastname,
        getEmail: state => state.user.email,
        getPhone: state => state.user.phone,
        getCreatedAt: state => state.user.created_at,
        getAge: state => state.user.age,
        getAvatar: state => state.user.avatar,
        getBirthday: state => state.user.birthday,
        getGender: state => state.user.gender,
        getFullName: state => state.user.full_name,
        getUserLoading: state => state.user_loading,
        // Photos ==============================================
        getPhotos: state => state.photos,
        getPhotosPagination: state => state.photos_pagination,
        getPhotosLoading: state => state.photos_loading,
        getSelectedPhoto: state => state.selected_photo,
        // Gifts ==================================================
        getGifts: state => state.gifts,
        getGiftsPagination: state => state.gifts_pagination,
        getSentGifts: state => state.sent_gifts,
        getSentGiftsPagination: state => state.sent_gifts_pagination,
        getGiftByIndex: state => index => state.gifts[index],
        getSentGiftByIndex: state => index => state.sent_gifts[index],
        getGiftsLoading: state => state.gifts_loading,
        // Interests ==============================================
        getInterests: state => state.interests,
        getRandomPopularInterests: state => state.random_popular_interests,
        getInterestsLoading: state => state.interests_loading,
        // Search For ==============================================
        getSearchingFor: state => state.searching_for,
        getSearchFor: state => state.searching_for.search_for,
        getSearchLimits: state => state.searching_for.search_limits,
        getSearchingForLoading: state => state.searching_for_loading,
        // Information ==============================================
        getInformation: state => state.information,
        getBiography: state => state.information.biography,
        getRelation: state => state.information.relation,
        getSexualOrientation: state => state.information.sexual_orientation,
        getBodyType: state => state.information.body_type,
        getILiveWith: state => state.information.i_live_with,
        getEyesColor: state => state.information.eyes_color,
        getHairColor: state => state.information.hair_color,
        getDrink: state => state.information.drink,
        getTobacco: state => state.information.tobacco,
        getInformationLoading: state => state.information_loading,
        // Geo ======================================================
        getGeo: state => state.geo,
        getLat: state => state.geo.lat,
        getLon: state => state.geo.lon,
        getGeoLoading: state => state.geo_loading,
        // Module Loaded ============================================
        getModulesLoaded: state => state.modules_loaded,
        // MyProgress ===============================================
        getMyProgress: state => data => {
            let percent = 20, nullers = [null, ''], errors = []

            // User Fullname
            if (/^User.*/.test(state.user.full_name,) == false) { percent += 10 }
            else { errors.push('full_name') }

            // User Avatar
            if (state.user.avatar != data.defaultAvatar) { percent += 10 }
            else { errors.push('avatar') }

            // User Phone
            if (!includes(state.user.phone, nullers)) { percent += 10 }
            else { errors.push('phone') }

            // User Photos
            if (state.photos.length >= 5) { percent += 10 }
            else { errors.push('photos') }

            // User Geo
            if (!includes(state.geo.lat, nullers) && !includes(state.geo.lon, nullers)) { percent += 10 }
            else { errors.push('location') }

            // User Interests
            if (state.interests.length >= 4) { percent += 10 }
            else { errors.push('interests') }

            // User Information
            if (some(state.information, isEmpty)) { percent += 20 }
            else { errors.push('information') }

            return { percent: percent, errors: errors }
        },
        // My Photo =================================================
        getMyPhoto: state => state.myphoto,
        getMyPhotoLoading: state => state.myphoto_loading,
        getMyPhotoComments: state => state.myphoto_comments,
        getMyPhotoCommentsPagination: state => state.myphoto_comments_pagination,
        getMyPhotoComment: state => state.myphoto_comment,
        getMyPhotoCommentsLoading: state => state.myphoto_comments_loading,
        getCountryId: state => state.countryId,
    },
    actions: {
        // User ==============================================
        getUser: ({ commit }) => {
            commit('setUserLoading', true)
            return window.axios.get('/profile/user/get').then(response => {
                commit('setUser', response.data)
                commit('setUserLoading', false)
                commit('addModuleLoaded', 'user')
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setUserLoading', false)
                throw error
            })
        },
        updateUser: ({ commit, getters }) => {
            commit('setUserLoading', true)
            return window.axios.post('/profile/user/update', getters.getUser).then(response => {
                commit('setUser', response.data)
                commit('setUserLoading', false)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setUserLoading', false)
                throw error
            })
        },
        uploadAvatar: ({ commit }, formData) => {
            commit('setUserLoading', true)
            return window.axios.post('/profile/user/avatar', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(response => {
                commit('setAvatar', response.data)
                commit('setUserLoading', false)
                return response
            }).catch(error => {
                console.log(error.message)
                commit('setUserLoading', false)
                throw error
            })
        },
        // Photos ==============================================
        getPhotos: ({ commit }, url) => {
            let route = '/profile/photos/get'
            if (url != null) { route = url }
            commit('setPhotosLoading', true)
            return window.axios.get(route).then(response => {
                commit('setPhotos', response.data.data)
                commit('setPhotosPagination', response.data)
                commit('setPhotosLoading', false)
                commit('addModuleLoaded', 'photos')
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setPhotosLoading', false)
                throw error
            })
        },
        uploadPhoto: ({ commit }, formData) => {
            commit('setPhotosLoading', true)
            return window.axios.post('/profile/photos/upload', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(response => {
                commit('addPhoto', response.data)
                commit('setPhotosLoading', false)
                return response
            }).catch(error => {
                console.log(error.message)
                commit('setPhotosLoading', false)
                throw error
            })
        },
        removePhoto: ({ commit }, id_photo) => {
            commit('setPhotosLoading', true)
            return window.axios.delete('/profile/photos/remove/' + id_photo).then(response => {
                commit('removePhoto', response.data)
                commit('setPhotosLoading', false)
                return response
            }).catch(error => {
                console.log(error.message)
                commit('setPhotosLoading', false)
                throw error
            })
        },
        // Gifts ================================================
        getGifts: ({ commit }, url) => {
            commit('setGiftsLoading', true)
            let route = '/profile/gifts/get'
            if (url != null) { route = url }
            return window.axios.get(route).then(response => {
                commit('setGifts', response.data.data)
                commit('setGiftsPagination', response.data)
                commit('setGiftsLoading', false)
                commit('addModuleLoaded', 'gifts')
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setGiftsLoading', false)
                throw error
            })
        },
        getSentGifts: ({ commit }, url) => {
            commit('setGiftsLoading', true)
            let route = '/profile/gifts/sent/get'
            if (url != null) { route = url }
            return window.axios.get(route).then(response => {
                commit('setSentGifts', response.data.data)
                commit('setSentGiftsPagination', response.data)
                commit('setGiftsLoading', false)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setGiftsLoading', false)
                throw error
            })
        },
        removeGift: ({ commit }, id_gift) => {
            commit('setGiftsLoading', true)
            return window.axios.delete('/profile/gifts/remove/' + id_gift).then(response => {
                commit('removeGift', response.data)
                commit('removeSentGift', response.data)
                commit('setGiftsLoading', false)
                return response
            }).catch(error => {
                console.log(error.message)
                commit('setGiftsLoading', false)
                throw error
            })
        },
        // Interests ==============================================
        getInterests: ({ commit }) => {
            commit('setInterestsLoading', true)
            return window.axios.get('/profile/interests/get').then(response => {
                commit('setInterests', response.data)
                commit('setInterestsLoading', false)
                commit('addModuleLoaded', 'interests')
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setInterestsLoading', false)
                throw error
            })
        },
        getRandomPopularInterests: ({ commit }) => {
            commit('setInterestsLoading', true)
            return window.axios.get('/profile/interests/popular').then(response => {
                commit('setRandomPopularInterests', response.data)
                commit('setInterestsLoading', false)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setInterestsLoading', false)
                throw error
            })
        },
        addInterest: ({ commit }, id_interest) => {
            commit('setInterestsLoading', true)
            return window.axios.post('/profile/interests/add', {
                id_interest: id_interest
            }).then(response => {
                commit('addInterest', response.data)
                commit('setInterestsLoading', false)
                return response
            }).catch(error => {
                console.log(error.message)
                commit('setInterestsLoading', false)
                throw error
            })
        },
        removeInterest: ({ commit }, id_interest) => {
            commit('setInterestsLoading', true)
            return window.axios.delete('/profile/interests/remove/' + id_interest).then(response => {
                commit('removeInterest', response.data)
                commit('setInterestsLoading', false)
                return response
            }).catch(error => {
                console.log(error.message)
                commit('setInterestsLoading', false)
                throw error
            })
        },
        // Search For  ==============================================
        getSearchingFor: ({ commit }) => {
            commit('setSearchingForLoading', true)
            return window.axios.get('/profile/searchingfor/get').then(response => {
                commit('setSearchingFor', response.data)
                commit('setSearchingForLoading', false)
                commit('addModuleLoaded', 'searchingfor')
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setSearchingForLoading', false)
                throw error
            })
        },
        updateSearchingFor: ({ commit, getters }) => {
            commit('setSearchingForLoading', true)
            return window.axios.post('/profile/searchingfor/update', getters.getSearchingFor).then(response => {
                commit('setSearchingFor', response.data)
                commit('setSearchingForLoading', false)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setSearchingForLoading', false)
                throw error
            })
        },
        // Information ===============================================
        getInformation: ({ commit, rootGetters }) => {
            commit('setInformationLoading', true)
            const params = new URLSearchParams()
            params.append('user_id', rootGetters['components/getUserDataId'])
            window.axios.get('profile/location-information/get', {params}).then(response => {
                if(response.data.country == null) {
                    commit('setCountryId', null)
                    return
                }
                commit('setCountryId', parseInt(response.data.country.id))
            }).catch(error => {
                console.error(error)
                throw error
            }).finally(() => {
                commit('setInformationLoading', false)
            })
            commit('setInformationLoading', true)
            return window.axios.get('/profile/information/get').then(response => {
                commit('setInformation', response.data)
                commit('setInformationLoading', false)
                commit('addModuleLoaded', 'information')
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setInformationLoading', false)
                throw error
            })

        },
        updateLocationInformation: ({commit, getters, rootGetters}) => {
            commit('setInformationLoading', true)
            return window.axios.post('/profile/location-information/update', {user_id: rootGetters['components/getUserDataId'], country_id: getters.getCountryId}).then(response => {
                return response.data;
            }).catch(error => {
                console.error(error);
                throw error
            }).finally(() => {
                commit('setInformationLoading', false)
            })
        },
        updateInformation: ({ commit, getters }) => {
            commit('setInformationLoading', true)
            return window.axios.post('/profile/information/update', getters.getInformation).then(response => {
                commit('setInformation', response.data)
                commit('setInformationLoading', false)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setInformationLoading', false)
                throw error
            })
        },
        // Geo  =======================================================
        getGeo: ({ commit }) => {
            commit('setGeoLoading', true)
            return window.axios.get('/profile/geo/get').then(response => {
                commit('setGeo', response.data)
                commit('setGeoLoading', false)
                commit('addModuleLoaded', 'geo')
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setGeoLoading', false)
                throw error
            })
        },
        updateGeo: ({ commit, getters }) => {
            commit('setGeoLoading', true)
            return window.axios.post('/profile/geo/update', getters.getGeo).then(response => {
                commit('setGeo', response.data)
                commit('setGeoLoading', false)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setGeoLoading', false)
                throw error
            })
        },
        // My Photo ====================================================
        getMyPhoto: ({ commit }, id_photo) => {
            commit('setMyPhotoLoading', true)
            return window.axios.get('/profile/myphoto/get/' + id_photo).then(response => {
                commit('setMyPhoto', response.data)
                commit('setMyPhotoLoading', false)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setMyPhotoLoading', false)
                throw error
            })
        },
        getMyPhotoComments: ({ commit, getters }, url) => {
            commit('setMyPhotoCommentsLoading', true)
            let route = '/profile/myphoto/comments'
            if (url != null) { route = url }
            return window.axios.get(route, { params: { id_photo: getters.getMyPhoto.id } }).then(response => {
                commit('setMyPhotoComments', response.data.data)
                commit('setMyPhotoCommentsPagination', response.data)
                commit('setMyPhotoCommentsLoading', false)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setMyPhotoCommentsLoading', false)
                throw error
            })
        },
        sendMyPhotoComment: ({ commit, getters }) => {
            commit('setMyPhotoCommentsLoading', true)
            return window.axios.post('/profile/myphoto/comment', {
                id_photo: getters.getMyPhoto.id,
                comment: getters.getMyPhotoComment
            }).then(response => {
                commit('addMyPhotoComment', response.data)
                commit('setMyPhotoComment', '')
                commit('setMyPhotoCommentsLoading', false)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setMyPhotoCommentsLoading', false)
                throw error
            })
        },
        deleteMyPhotoComment: ({ commit, getters }, id_comment) => {
            commit('setMyPhotoCommentsLoading', true)
            return window.axios.post('/profile/myphoto/comment/delete', {
                id_photo: getters.getMyPhoto.id,
                id_comment: id_comment
            }).then(response => {
                commit('deleteMyPhotoComment', response.data)
                commit('setMyPhotoCommentsLoading', false)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setMyPhotoCommentsLoading', false)
                throw error
            })
        }
    }
}
