export const events = {
    CONNECT: "$connect",
    DISCONNECT: "$disconnect",
    SET_USER_ID: "set_user_id",
    MESSAGE_READ: "message_read",
    SEND_MESSAGE: "send_message",
    RECEIVE_MESSAGE: "receive_message",
    USER_TYPING: "user_typing",
    PING: "ping",
    PONG: "pong",
}
