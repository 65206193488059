const Base = () => import('./base.vue')
const Account = () => import('./account.vue')
const Security = () => import('./security.vue')
const Notifications = () => import('./notifications.vue')
const Contact = () => import('./contact.vue')

export default [
    {
        path: '/settings',
        component: Base,
        name: 'Settings',
        redirect: '/settings/account',
        children: [
            {
                name: 'Account',
                path: 'account',
                component: Account
            },
            {
                name: 'Security And Privacy',
                path: 'security',
                component: Security
            },
            {
                name: 'Notifications',
                path: 'notifications',
                component: Notifications
            },
            {
                name: 'Contact',
                path: 'contact',
                component: Contact
            }
        ]
    }
]
