import axios from 'axios';
import config from "./config";
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.baseURL = config.publicUrl + "api";
// window.axios.defaults.withXSRFToken = true;
// window.axios.defaults.withCredentials = true;

// Check for Token
// Add a request interceptor
window.axios.interceptors.request.use((req) => {
    if (localStorage.getItem("access_token") != null) {
        req.headers.authorization =
            "Bearer " + localStorage.getItem("access_token");
    }
    return req;
});

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allow your team to quickly build robust real-time web applications.
 */

import './echo';
