import { findIndex, forEach, map, omit } from 'lodash-es'

export default {
    namespaced: true,
    state: {
        activities: [],
        pagination: null,
        loading: false,
        unread_count: 0,
        is_open_notification_panel: false
    },
    mutations: {
        setActivities: (state, payload) => state.activities = payload,
        setActivitiesPagination: (state, payload) => state.pagination = omit(payload, ['data']),
        addActivities: (state, payload) => forEach(payload, p => { state.activities.push(p) }),
        addActivity: (state, payload) => {
            state.activities.unshift(payload)
            state.unread_count++
        },
        setReadActivities: state => map(state.activities, activity => { return activity.read = true }),
        setActivitiesLoading: (state, payload) => state.loading = payload,
        setUnreadCount: (state, payload) => state.unread_count = payload,
        plusUnreadCount: state => state.unread_count++,
        setIsOpenNotificatonPanel: (state, payload) => state.is_open_notification_panel = payload
    },
    getters: {
        getActivities: state => state.activities,
        getActivitiesPagination: state => state.pagination,
        getActivityById: state => id_activity => {
            let index = findIndex(state.activities, o => o.id == id_activity)
            if (index != -1) { return state.activities[index] } else { return null }
        },
        getActivitiesLoading: state => state.loading,
        getUnreadCount: state => state.unread_count,
        getIsOpenNotificatonPanel: state => state.is_open_notification_panel
    },
    actions: {
        getActivities: ({ commit, getters }, url) => {
            commit('setActivitiesLoading', true)
            let route = '/activities/get'
            if (url != null) { route = url }
            return window.axios.get(route).then(response => {
                if (getters.getActivities.length != 0) {
                    commit('addActivities', response.data.data)
                } else {
                    commit('setActivities', response.data.data)
                }
                commit('setActivitiesPagination', response.data)
                commit('setActivitiesLoading', false)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setActivitiesLoading', false)
                throw error
            })
        },
        setReadActivities: ({ commit, getters }) => {
            if (getters.getUnreadCount == 0) { return }
            window.axios.post('/activities/read').then(() => {
                // commit('setReadActivities')
                commit('setUnreadCount', 0)
            }).catch(error => {
                console.log(error)
            })
        },
        getCountUnreadActivities: ({ commit }) => {
            window.axios.get('/activities/count').then(response => {
                commit('setUnreadCount', response.data)
            }).catch(error => {
                console.log(error)
            })
        },
        addOrCountActivity: ({ commit, getters }, activity) => {
            if (getters.getIsOpenNotificatonPanel) {
                commit('addActivity', activity)
            } else {
                commit('plusUnreadCount')
            }
        }
    }
}
