
export default [
    {
        path: '/posts',
        component: () => import('../../components/basemodule.vue'),
        name: 'Posts',
        redirect: '/posts/index',
        children: [
            {
                name: 'Posts List',
                path: 'index',
                component: () => import('./index.vue'),
                meta: { requireBeApproved: true, notTimeOut: true, notSuspendedUser: true }
            }
        ]
    }
]
