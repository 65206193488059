export default {
    message: {
        hello: 'hello world'
    },
    test: 'test on english',
    inputs: {
        no: 'No',
        yes: 'Yes',
        remove: 'Remove',
        search: 'Search',
        return: 'Return',
        comment: 'Comment',
        delete: 'Delete',
        cancel: 'Cancel',
        all: 'All',
        button: {
            apply: 'Apply'
        },
        select: {
            default_name_value_text: 'Select an option'
        },
        notifications: {
            notification: 'Notification',
            errors: 'There are some errors...',
            server: 'Server Error. Please try again later...',
            deleted: 'Deleted!',
            updated: 'Updated!',
            created: 'Created!'
        },
        pagination: {
            next: 'Next',
            previous: 'Previous',
            load_more: 'Load More'
        },
        image_upload_errors: {
            size: 'The image should not be larger than 10MB',
            format: 'The file must be an image file',
            required: 'Image is required',
            server_error: 'Server Error. Please try again later.'
        }
    },
    modules: {
        news: 'News',
        chat: 'Chat',
        meeting: 'Meeting',
        store: 'Store',
        featured_profiles: 'Featured Profiles',
        new_members: 'New Profiles',
        credits: 'Credits',
        memberships: 'Memberships',
        profile: 'Profile',
        settings: 'Settings'
    },
    menu: {
        match: 'Match',
        chat: 'Chat',
        news: 'News',
        credits: 'Credits',
        memberships: 'Memberships',
        gift_store: 'Gift Store',
        new_profiles: 'New Profiles',
        featured_profiles: 'Featured Profiles',
    },
    news: {
        messages: {
            avatar: '{name} has a new avatar!',
            membership: '{name} has a new membership!',
            photo: '{name} has uploaded a new photo!'
        }
    },
    meeting: {
        filters_title: 'Search Filters',
        name: 'User name',
        age_range: 'Age Range',
        city: 'City',
        distance_range: 'Distance Range',
        search_for: 'Search For',
        with_photos: 'With Photos',
        new_members: 'New Members',
        featured_members: 'Featured Members',
        body_type: 'Body Type',
        search_limits: 'Limits',
        clean_fields: 'Clean Fields',
        apply: 'Apply',
        say_hi: 'Say Hi!',
        send_gift: 'Send a gift!',
        filters: 'Filters',
        memberships: 'Memberships',
        all_search_for: 'All search for',
        all_body_type: 'All Body Types',
        all_search_limits: 'All limits',
        no_results: 'No Results...',
        search_by_user_name: "Search by user's name",
        no_photos: 'User has no photos...'
    },
    chat: {
        search_message: 'Search a message',
        results: 'results',
        title_mini: 'Chat',
        add_to_favorites: 'Add to Favorites',
        remove_from_favorites: 'Remove from Favorites',
        send_gift: 'Send Gift',
        block_user: 'Block User',
        unblock_user: 'Unblock User',
        delete_conversation: 'Delete Conversation',
        search_conversation: 'Search Conversation...',
        conversations: 'Conversations',
        load_more: 'Load More',
        type_message: 'Type a Message...',
        quote: 'Quote',
        delete: 'Delete',
        user_to_typing: ' is tyipng...',
        there_are_no_conversations: 'There are no conversations...',
        start_chatting_users: 'Start chatting with these users',
        image_upload_errors: {
            size: 'The image should not be larger than 2MB',
            format: 'The file must be an image file',
            required: 'Image is required',
            server_error: 'Server Error. Please try again later.'
        },
        notifications: {
            added_to_favorites: 'User has been added to your favorites!',
            remove_from_favorites: 'User has been removed from your favorites!',
            user_blocked: 'User blocked!',
            user_unblocked: 'User unblocked!',
            gift_sent_to_user: 'The gift has been sent to selected user!',
            title_delete_conversation: 'Delete Conversation',
            delete_it: 'Delete it',
            cancel: 'Cancel',
            sure_to_delete_conversation: 'Are you sure you want to delete the conversation?',
            conversation_deleted: 'Conversation has been deleted!'
        },
        emoji: {
            search: 'Search',
            notfound: 'No Emoji Found',
            categories: {
                search: 'Search Results',
                recent: 'Frequently Used',
                people: 'Smileys & People',
                nature: 'Animals & Nature',
                foods: 'Food & Drink',
                activity: 'Activity',
                places: 'Travel & Places',
                objects: 'Objects',
                symbols: 'Symbols',
                flags: 'Flags',
                custom: 'Custom',
            }
        }
    },
    gifts: {
        all: 'All',
        sending_to: 'You are sending this gift to <b class="title_red">{name}</b>',
        write_to: 'Write something nice',
        send_gift: 'Send Gift',
        cancel: 'Cancel',
        load_more: 'Load More',
        search_user: 'Search User...',
        notifications: {
            gift_sent_to: 'Gift sent to {name}',
            server_error: 'Server Error. Please try again later.',
            need_more_credits: 'You need more credits! Buy more clicking <strong>here!</strong>',
            please_select_user: 'Please select the user...',
            plase_fill_message: 'Plase send a message to the user.'
        },
        opening: {
            congratulations: 'Congratulations! You have a new gift!',
            from: 'From',
            message: 'Message'
        },
        title: 'Store',
        giveaway: 'Give away',
        send_to: 'Send to',
        chat_with: 'Chat with {name}',
        back_to_store: 'Back to store',
        go_to_profile: 'Go to {name} profile',
        back_to_my_profile: 'Back to my profile',
        activities: {
            you_have_a_new_gift: 'You have a new gift!',
            sent_you_gift: '{name} sent you a gift'
        }
    },
    profiles: {
        title: 'Profiles',
        featured_profiles: 'Featured Profiles',
        new_profiles: 'New Profiles',
        distance: '{number} km away',
        description_title: 'About {name}',
        interests_title: 'Interests',
        like: 'Like',
        comment: 'Comment',
        write_something_input: 'Write something about your photo',
        load_more: 'Load more comments',
        activities: {
            new_comment: 'New Comment',
            new_comment_photo: 'You have a new comment on a photo',
            new_like: 'New Like',
            new_like_photo: 'You have a new like on a photo',
            new_view: 'New Profile View!',
            new_view_user: '{name} has seen your profile',
            new_view_hidden: 'A user has viewed your profile'
        },
        information: {
            title: 'Personal Information',
            relation: 'Relation',
            sexual_orientation: 'Sexual Orientation',
            body_type: 'Body type',
            i_live_with: 'I live with',
            eye_color: 'Eye Color',
            hair_color: 'Hair Color',
            drink: 'Drink',
            tobacco: 'Tobacco',
            show: 'Show',
            country: 'Country',
        }
    },
    memberships: {
        please_select: 'Please select your membership',
        days: '{number} Days',
        minutes: '{number} Minutes',
        contains: 'See what it contains',
        payment_method: 'Payment Method',
        popular: 'Popular',
        free: 'Free',
        pricing_text: '${price} USD',
        i_want_this: 'I want this',
        you_are_going_to_buy: 'You are going to buy',
        for: 'For {money}',
        containers: {
            start_chat_dialog: 'Start chat dialog',
            read_chat_messages: 'Read chat messages',
            continue_chat_dialog: 'Continue chat dialog',
            to_see_photos: 'To see photos',
            upload_photos: 'Upload photos',
            comment_photos: 'Comment photos',
            allow_posts_on_profile_wall: 'Allow posts on profile wall',
            search_users: 'Search users',
            see_profiles: 'See Profiles',
            send_presents: 'Send presents',
            add_to_favorites: 'Add to favorites',
            to_call: 'To call',
            answer: 'Answer',
            change_preferences: 'Change preferences',
            allow_comment_status_updates: 'Allow comment status updates',
            allow_status_updates: 'Allow Status Updates'
        },
        notifications: {
            has_not_expired: 'Your current membership has not expired',
            want_new_one: 'Do you want to buy a new one?',
            success: 'Thank you for your purchase, your membership has been updated!.',
            buy_canceled: 'Membership purchase canceled!',
            membership_expired: 'You membership has expired!'
        }
    },
    credits: {
        please_select: 'Please select your coin package',
        credits: '{number} Credits',
        payment_method: 'Payment Method',
        popular: 'Popular',
        you_are_going_to_buy: 'You are going to buy',
        for: 'For {money}',
        notifications: {
            success: 'Thank you for your purchase, your credits have been added to your wallet.',
            buy_canceled: 'Credits purchase canceled'
        }
    },
    user_menu: {
        logout: 'Logout',
        profile: 'Profile',
        settings: 'Settings',
        install_pwa: 'Install PWA App',
        copy_referred_link: 'Copy referrer link',
        notifications: {
            copied: 'Link copied successfully',
        },
        pwa_available: "Get our free app. It won't take up space on your device",
        install: 'Install',
        dark_mode: {
            title: 'Dark Mode',
            configuration: {
                system: 'Automatic (System)',
                enabled: 'Enabled',
                disabled: 'Disabled'
            }
        }
    },

    pay_shortcuts: {
        memberships: 'Memberships',
        credits: 'Credits'
    },
    online: {
        favorite_online: '{name} is online!'
    },
    favorites: {
        title: 'Favorites',
        no_favorites_added: 'No favorites have been added...',
        activities: {
            new_favorite: 'New Favorite',
            new_favorite_user: 'A user has followed you'
        }
    },
    online_users: {
        title: 'Online Users',
        there_are_no_users_online: 'There are no online users...'
    },
    profile: {
        title: 'Profile',
        placeholders: {
            email: 'mymail@gmail.com',
            phone: '6121366758',
            birthday: 'Birthday',
            gender: 'Gender'
        },
        member_since: 'Member since ',
        add_photo: 'Add Photo',
        image_upload_errors: {
            size: 'The image should not be larger than 2MB',
            format: 'The file must be an image file',
            required: 'Image is required',
            server_error: 'Server Error. Please try again later.'
        },
        delete_photo_modal_title: 'Deleting Photo',
        delete_photo_modal_text: 'You are going to delete to this photo',
        cancel: 'Cancel',
        delete_it: 'Delete it!',
        notifications: {
            image_uploaded: 'The image has been added!',
            photo_deleted: 'Photo deleted!',
            updated_personal: 'User Personal Information Updated!',
            user_not_approved: 'Your user is in the process of being verified, in the meantime and if you wish you can fill in your personal data to make yourself known to others. Thank you for your patience.',
            user_time_out: 'Your free time is up. If you want to continue in the application please buy a membership.',
            user_suspended: 'Your account has been suspended, you can check in support what the reason has been.'
        },
        pagination: {
            next: 'Next',
            previous: 'Previous'
        },
        gifts: {
            tab1: 'Gifts received',
            tab2: 'Sent gifts',
            sent_by_name: 'Gift sent by {name}',
            recived_date: 'Received {date}',
            sent_to_name: 'Gift Sent to {name}',
            delete_gift_modal_title: 'Deleting gift',
            delete_gift_modal_text: 'You are going to delete to this gift!',
            cancel: 'Cancel',
            delete_it: 'Delete it!',
            notifications: {
                removed: 'Gift removed!'
            }
        },
        complete_profile: 'Complete Profile',
        myprogress: {
            errors: {
                full_name: "Change the user's full name",
                phone: 'Add phone number',
                photos: 'Add at least 5 photos',
                avatar: 'Change avatar',
                location: 'Update your location',
                interests: 'Add at least 4 interests',
                information: 'Update your personal information'
            }
        },
        location: {
            title: 'Location',
            update: 'Update Location',
            get_current: 'Get current location',
            notifications: {
                updated: 'Location has been updated!'
            }
        },
        interests: {
            title: 'Interests',
            close: 'Close',
            load_more: 'Load More',
            more_popular: 'More popular interests',
            delete_interest_modal_title: 'Deleting Interest',
            delete_interest_modal_text: 'You are going to delete to this interest!',
            cancel: 'Cancel',
            delete_it: 'Delete it!',
            notifications: {
                added: 'Interest added!',
                removed: 'Interest removed!'
            }
        },
        searching_for: {
            title: 'Search For',
            default_search_for_text: 'Search For',
            default_search_limits_text: 'Search Limits'
        },
        personal_information: {
            title: 'Personal Information',
            biography_placeholder: 'Write about yourself, an interesting description!',
            relation: 'Relation',
            sexual_orientation: 'Sexual Orientation',
            body_type: 'Body type',
            i_live_with: 'I live with',
            eye_color: 'Eye Color',
            hair_color: 'Hair Color',
            drink: 'Drink',
            tobacco: 'Tobacco',
            clean_fields: 'Clean Fields',
            apply: 'Apply',
            notifications: {
                updated: 'Personal Information Updated!',
                country_updated: 'Country updated!',
            },
            country: 'Country',
        },
        my_photos: {
            title: 'My Photos',
            delete_comment: 'Deleting Comment',
            warning_delete_comment: 'You are going to delete to this comment!'
        }
    },
    settings: {
        title: 'Settings',
        menu: {
            account: 'Account',
            security_privacy: 'Security and Privacy',
            notifications: 'Notifications',
            contact_us: 'Contact us'
        },
        account: {
            title: 'Account',
            placeholders: {
                name: 'Name',
                lastname: 'Lastname',
                email: 'Email',
                gender: 'Gender',
                language: 'Language',
                id_profile: 'ID Profile',
                postal_code: 'Postal Code',
                delete_account: 'Delete Account',
                deactivate_account: 'Deactivate account'
            },
            apply: 'Apply',
            delete_account_warning: 'You are going to delete your account!',
            delete_account_question: 'Are you ABSOLUTELY SURE you wish to delete it?',
            delete_account_confirm: 'Yes, delete my account! ',
            notifications: {
                updated: 'Account updated!'
            }
        },
        security: {
            title: 'Security and Privacy',
            placeholders: {
                change_password: 'Change Password',
                password: 'Password',
                old_password: 'Old Password',
                password_confirmation: 'Password Confirmation',
                security_question: 'Security Question',
                question: 'Question',
                answer: 'Answer',
                input_question: 'What was the name of my first pet?',
                input_answer: 'pluto',
                blocked_profiles: 'Blocked Profiles',
                unblock: 'Unblock',
                hide_profile: 'Hide Profile'
            },
            notifications: {
                password_updated: 'Password Updated!',
                question_updated: 'Security Question Updated!',
                updated: 'Security and Privacy updated!',
                unblocked: 'User unblocked!'
            }
        },
        notifications: {
            title: 'Email me when',
            placeholders: {
                send_me_a_gift: 'Send me a gift',
                favorite_member_sign_in: 'A favorite member sign in',
                someone_visit_my_profile: 'Someone visit my profile',
                a_new_member_in_my_area_login: 'A new member in my area login',
                special_offers: 'Special offers'
            },
            notifications: {
                updated: 'Notifications updated!'
            }
        },
        contact: {
            title: 'Contact Us',
            placeholders: {
                email_us: 'Email Us',
                start_chat: 'Start chat',
                subject: 'Subject',
                message: 'Message',
                subject_example: 'I have problems with the payment method for credits',
                message_example: 'I cannot buy credits because my card does not pass...'
            },
            notifications: {
                message_sent: 'The message was sent!',
                contact_with_you: 'Please, be patient. We will contact you soon!'
            }
        }
    },
    videocall: {
        end_call: 'End Call',
        calling: 'Calling',
        timeout: 'Call Timeout!',
        question: 'You have a call from: {name}. Would you like to answer?',
        user_ended_call: '{name} has ended videocall...',
        user_declined_call: '{name} has declined videocall request...',
        user_is_in_videocall: '{name} has a videocall already...',
        user_cancel_videocall: '{name} cancel videocall...',
        note: 'Note',
        need_more_credit_error: "You don't have enough credits for the video call",
        answer: 'Answer',
        cancel: 'Cancel',
        reject: 'Reject',
        calling_from: 'Calling from {name}',
        charged: 'Charged {money} credits.',
        user_disconnected: 'Disconnection of the other user...',
        connection_failed: 'Connection failed...',
        user_to_need_more_credit_error: '{name} does not have enough credits for the video call',
    },
    countdown: {
        time_over: 'Time Over'
    },
    legal: {
        terms: 'Terms of Service',
        privacy: 'Privacy Policy'
    },
    wenjoy: {
        first_name: 'Name',
        last_name: 'Lastname',
        email: 'Email',
        cellphone: 'Phone',
        document_type: 'Document Type',
        document_number: 'Document Number',
        pay: 'Pay',
        document_type_options: {
            citizenship_card: 'Citizenship card',
            tax_identification_number: 'Tax identification number (NIT)',
            foreigner_id: 'Foreigner ID',
            passport: 'Passport'
        },
        thanks_for_your_purchase: 'Thanks for your purchase!',
        enjoy_your_credits: 'Enjoy your credits!',
        enjoy_your_membership: 'Enjor your membership!',
        back_to_credits: 'Back to credits',
        back_to_memberships: 'Back to memberships',
        back_to_news: 'Back to news'
    },
    activities: {
        gift: '{name} sent you a gift',
        profile_view: '{name} has seen your profile',
        profile_view_hidden: 'A user has viewed your profile',
        photo_comment: '{name} has commented on your photo',
        photo_like: '{name} likes your photo',
        favorite: '{name} has followed you'


    }
}
