export default {
    message: {
        hello: "olá mundo"
    },
    test: "teste em inglês",
    inputs: {
        no: 'Não',
        yes: 'Sim',
        remove: 'Remover',
        search: 'Buscar',
        return: 'Retornar',
        comment: 'Comentário',
        delete: 'Excluir',
        cancel: 'Cancelar',
        all: 'Todos',
        button: {
            apply: 'Aplicar'
        },
        select: {
            default_name_value_text: 'Selecione uma opção'
        },
        notifications: {
            notification: 'Notificação',
            errors: 'Há alguns erros...',
            server: 'Erro no servidor. Por favor, tente novamente mais tarde...',
            deleted: 'Excluído!',
            updated: 'Atualizado!',
            created: 'Criado!'
        },
        pagination: {
            next: 'Próximo',
            previous: 'Anterior',
            load_more: 'Carregar mais'
        },
        image_upload_errors: {
            size: 'A imagem não deve ser maior que 10MB',
            format: 'O arquivo deve ser um arquivo de imagem',
            required: 'Imagem é obrigatória',
            server_error: 'Erro no servidor. Por favor, tente novamente mais tarde.'
        }
    },
    modules: {
        news: 'Notícias',
        chat: 'Chat',
        meeting: 'Reunião',
        store: 'Loja',
        featured_profiles: 'Perfis em Destaque',
        new_members: 'Novos Perfis',
        credits: 'Créditos',
        memberships: 'Assinaturas',
        profile: 'Perfil',
        settings: 'Configurações'
    },
    menu: {
        match: 'Partida',
        chat: 'Chat',
        news: 'Notícias',
        credits: 'Créditos',
        memberships: 'Assinaturas',
        gift_store: 'Loja de Presentes',
        new_profiles: 'Novos Perfis',
        featured_profiles: 'Perfis em Destaque'
    },
    news: {
        messages: {
            avatar: '{name} tem um novo avatar!',
            membership: '{name} tem uma nova assinatura!',
            photo: '{name} enviou uma nova foto!'
        }
    },
    meeting: {
        filters_title: 'Filtros de Pesquisa',
        name: 'Nome do Usuário',
        age_range: 'Faixa Etária',
        city: 'Cidade',
        distance_range: 'Faixa de Distância',
        search_for: 'Buscar Por',
        with_photos: 'Com Fotos',
        new_members: 'Novos Membros',
        featured_members: 'Membros em Destaque',
        body_type: 'Tipo de Corpo',
        search_limits: 'Limites',
        clean_fields: 'Limpar Campos',
        apply: 'Aplicar',
        say_hi: 'Diga Oi!',
        send_gift: 'Enviar um presente!',
        filters: 'Filtros',
        memberships: 'Assinaturas',
        all_search_for: 'Todas as buscas por',
        all_body_type: 'Todos os Tipos de Corpo',
        all_search_limits: 'Todos os limites',
        no_results: 'Nenhum Resultado...',
        search_by_user_name: 'Buscar pelo nome do usuário',
        no_photos: 'O usuário não tem fotos...'
    },
    chat: {
        search_message: 'Buscar uma mensagem',
        results: 'resultados',
        title_mini: 'Chat',
        add_to_favorites: 'Adicionar aos Favoritos',
        remove_from_favorites: 'Remover dos Favoritos',
        send_gift: 'Enviar Presente',
        block_user: 'Bloquear Usuário',
        unblock_user: 'Desbloquear Usuário',
        delete_conversation: 'Excluir Conversa',
        search_conversation: 'Buscar Conversa...',
        conversations: 'Conversas',
        load_more: 'Carregar Mais',
        type_message: 'Digite uma Mensagem...',
        quote: 'Citar',
        delete: 'Excluir',
        user_to_typing: ' está digitando...',
        there_are_no_conversations: 'Não há conversas...',
        start_chatting_users: 'Comece a conversar com esses usuários',
        image_upload_errors: {
            size: 'A imagem não deve ser maior que 2MB',
            format: 'O arquivo deve ser um arquivo de imagem',
            required: 'Imagem é obrigatória',
            server_error: 'Erro no servidor. Por favor, tente novamente mais tarde.'
        },
        notifications: {
            added_to_favorites: 'Usuário adicionado aos seus favoritos!',
            remove_from_favorites: 'Usuário removido dos seus favoritos!',
            user_blocked: 'Usuário bloqueado!',
            user_unblocked: 'Usuário desbloqueado!',
            gift_sent_to_user: 'O presente foi enviado ao usuário selecionado!',
            title_delete_conversation: 'Excluir Conversa',
            delete_it: 'Excluir',
            cancel: 'Cancelar',
            sure_to_delete_conversation: 'Tem certeza de que deseja excluir a conversa?',
            conversation_deleted: 'Conversa excluída!'
        },
        emoji: {
            search: 'Buscar',
            notfound: 'Nenhum Emoji Encontrado',
            categories: {
                search: 'Resultados da Pesquisa',
                recent: 'Usados Recentemente',
                people: 'Sorrisos & Pessoas',
                nature: 'Animais & Natureza',
                foods: 'Comida & Bebida',
                activity: 'Atividades',
                places: 'Viagens & Lugares',
                objects: 'Objetos',
                symbols: 'Símbolos',
                flags: 'Bandeiras',
                custom: 'Personalizado'
            }
        }
    },
    gifts: {
        all: 'Todos',
        sending_to: 'Você está enviando este presente para <b class="title_red">{name}</b>',
        write_to: 'Escreva algo legal',
        send_gift: 'Enviar Presente',
        cancel: 'Cancelar',
        load_more: 'Carregar Mais',
        search_user: 'Buscar Usuário...',
        notifications: {
            gift_sent_to: 'Presente enviado para {name}',
            server_error: 'Erro no servidor. Por favor, tente novamente mais tarde.',
            need_more_credits: 'Você precisa de mais créditos! Compre mais clicando <strong>aqui!</strong>',
            please_select_user: 'Por favor, selecione o usuário...',
            plase_fill_message: 'Por favor, envie uma mensagem para o usuário.'
        },
        opening: {
            congratulations: 'Parabéns! Você tem um novo presente!',
            from: 'De',
            message: 'Mensagem'
        },
        title: 'Loja',
        giveaway: 'Sorteio',
        send_to: 'Enviar para',
        chat_with: 'Conversar com {name}',
        back_to_store: 'Voltar para a loja',
        go_to_profile: 'Ir para o perfil de {name}',
        back_to_my_profile: 'Voltar para o meu perfil',
        activities: {
            you_have_a_new_gift: 'Você tem um novo presente!',
            sent_you_gift: '{name} enviou um presente para você'
        }
    },
    profiles: {
        title: 'Perfis',
        featured_profiles: 'Perfis em Destaque',
        new_profiles: 'Novos Perfis',
        distance: '{number} km de distância',
        description_title: 'Sobre {name}',
        interests_title: 'Interesses',
        like: 'Curtir',
        comment: 'Comentar',
        write_something_input: 'Escreva algo sobre sua foto',
        load_more: 'Carregar mais comentários',
        activities: {
            new_comment: 'Novo Comentário',
            new_comment_photo: 'Você tem um novo comentário em uma foto',
            new_like: 'Novo Curtida',
            new_like_photo: 'Você tem uma nova curtida em uma foto',
            new_view: 'Nova Visualização de Perfil!',
            new_view_user: '{name} visualizou seu perfil',
            new_view_hidden: 'Um usuário visualizou seu perfil'
        },
        information: {
            title: 'Informações Pessoais',
            relation: 'Relação',
            sexual_orientation: 'Orientação Sexual',
            body_type: 'Tipo de Corpo',
            i_live_with: 'Eu moro com',
            eye_color: 'Cor dos Olhos',
            hair_color: 'Cor do Cabelo',
            drink: 'Álcool',
            tobacco: 'Tabaco',
            show: 'Mostrar',
            country: 'País'
        }
    },
    memberships: {
        please_select: 'Por favor, selecione sua assinatura',
        days: '{number} Dias',
        minutes: '{number} Minutos',
        contains: 'Veja o que contém',
        payment_method: 'Método de Pagamento',
        popular: 'Popular',
        free: 'Gratuito',
        pricing_text: '${price} USD',
        i_want_this: 'Eu quero isso',
        you_are_going_to_buy: 'Você está prestes a comprar',
        for: 'Por {money}',
        containers: {
            start_chat_dialog: 'Iniciar diálogo de chat',
            read_chat_messages: 'Ler mensagens de chat',
            continue_chat_dialog: 'Continuar diálogo de chat',
            to_see_photos: 'Para ver fotos',
            upload_photos: 'Enviar fotos',
            comment_photos: 'Comentar fotos',
            allow_posts_on_profile_wall: 'Permitir postagens na parede do perfil',
            search_users: 'Buscar usuários',
            see_profiles: 'Ver Perfis',
            send_presents: 'Enviar presentes',
            add_to_favorites: 'Adicionar aos favoritos',
            to_call: 'Para ligar',
            answer: 'Responder',
            change_preferences: 'Alterar preferências',
            allow_comment_status_updates: 'Permitir atualizações de status de comentários',
            allow_status_updates: 'Permitir atualizações de status'
        },
        notifications: {
            has_not_expired: 'Sua assinatura atual não expirou',
            want_new_one: 'Você deseja comprar uma nova?',
            success: 'Obrigado pela sua compra, sua assinatura foi atualizada!',
            buy_canceled: 'Compra da assinatura cancelada!',
            membership_expired: 'Sua assinatura expirou!'
        }
    },
    credits: {
        please_select: 'Por favor, selecione seu pacote de créditos',
        credits: '{number} Créditos',
        payment_method: 'Método de Pagamento',
        popular: 'Popular',
        you_are_going_to_buy: 'Você está prestes a comprar',
        for: 'Por {money}',
        notifications: {
            success: 'Obrigado pela sua compra, seus créditos foram adicionados à sua carteira.',
            buy_canceled: 'Compra de créditos cancelada'
        }
    },
    user_menu: {
        logout: 'Sair',
        profile: 'Perfil',
        settings: 'Configurações',
        install_pwa: 'Instalar Aplicativo PWA',
        copy_referred_link: 'Copiar link de referência',
        notifications: {
            copied: 'Link copiado com sucesso',
        },
        pwa_available: 'Obtenha nosso aplicativo gratuito. Ele não ocupará espaço no seu dispositivo',
        install: 'Instalar',
        dark_mode: {
            title: 'Modo oscuro',
            configuration: {
                system: 'Automático (Sistema)',
                enabled: 'Ativado',
                disabled: 'Desativado'
            }
        }
    },
    pay_shortcuts: {
        memberships: 'Assinaturas',
        credits: 'Créditos'
    },
    online: {
        favorite_online: '{name} está online!'
    },
    favorites: {
        title: 'Favoritos',
        no_favorites_added: 'Nenhum favorito adicionado...',
        activities: {
            new_favorite: 'Novo Favorito',
            new_favorite_user: 'Um usuário começou a seguir você'
        }
    },
    online_users: {
        title: 'Usuários Online',
        there_are_no_users_online: 'Não há usuários online...'
    },
    profile: {
        title: 'Perfil',
        placeholders: {
            email: 'mymail@gmail.com',
            phone: '6121366758',
            birthday: 'Data de Nascimento',
            gender: 'Gênero'
        },
        member_since: 'Membro desde ',
        add_photo: 'Adicionar Foto',
        image_upload_errors: {
            size: 'A imagem não deve ser maior que 2MB',
            format: 'O arquivo deve ser uma imagem',
            required: 'Imagem é obrigatória',
            server_error: 'Erro no servidor. Por favor, tente novamente mais tarde.'
        },
        delete_photo_modal_title: 'Excluir Foto',
        delete_photo_modal_text: 'Você está prestes a excluir esta foto',
        cancel: 'Cancelar',
        delete_it: 'Excluir!',
        notifications: {
            image_uploaded: 'A imagem foi adicionada!',
            photo_deleted: 'Foto excluída!',
            updated_personal: 'Informações pessoais do usuário atualizadas!',
            user_not_approved: 'Seu usuário está em processo de verificação, enquanto isso, se desejar, você pode preencher seus dados pessoais para se tornar mais conhecido. Obrigado pela paciência.',
            user_time_out: 'Seu tempo livre acabou. Se deseja continuar no aplicativo, compre uma assinatura.',
            user_suspended: 'Sua conta foi suspensa. Você pode verificar com o suporte o motivo da suspensão.'
        },
        pagination: {
            next: 'Próximo',
            previous: 'Anterior'
        },
        gifts: {
            tab1: 'Presentes recebidos',
            tab2: 'Presentes enviados',
            sent_by_name: 'Presente enviado por {name}',
            recived_date: 'Recebido {date}',
            sent_to_name: 'Presente enviado para {name}',
            delete_gift_modal_title: 'Excluir presente',
            delete_gift_modal_text: 'Você está prestes a excluir este presente!',
            cancel: 'Cancelar',
            delete_it: 'Excluir!',
            notifications: {
                removed: 'Presente removido!'
            }
        },
        complete_profile: 'Completar Perfil',
        myprogress: {
            errors: {
                full_name: 'Altere o nome completo do usuário',
                phone: 'Adicione o número de telefone',
                photos: 'Adicione pelo menos 5 fotos',
                avatar: 'Altere o avatar',
                location: 'Atualize sua localização',
                interests: 'Adicione pelo menos 4 interesses',
                information: 'Atualize suas informações pessoais'
            }
        },
        location: {
            title: 'Localização',
            update: 'Atualizar Localização',
            get_current: 'Obter localização atual',
            notifications: {
                updated: 'Localização atualizada!'
            }
        },
        interests: {
            title: 'Interesses',
            close: 'Fechar',
            load_more: 'Carregar Mais',
            more_popular: 'Interesses mais populares',
            delete_interest_modal_title: 'Excluir Interesse',
            delete_interest_modal_text: 'Você está prestes a excluir este interesse!',
            cancel: 'Cancelar',
            delete_it: 'Excluir!',
            notifications: {
                added: 'Interesse adicionado!',
                removed: 'Interesse removido!'
            }
        },
        searching_for: {
            title: 'Procurando',
            default_search_for_text: 'Procurando',
            default_search_limits_text: 'Limites de Busca'
        },
        personal_information: {
            title: 'Informações Pessoais',
            biography_placeholder: 'Escreva sobre você, uma descrição interessante!',
            relation: 'Relação',
            sexual_orientation: 'Orientação Sexual',
            body_type: 'Tipo de Corpo',
            i_live_with: 'Eu moro com',
            eye_color: 'Cor dos Olhos',
            hair_color: 'Cor do Cabelo',
            drink: 'Álcool',
            tobacco: 'Tabaco',
            clean_fields: 'Limpar Campos',
            apply: 'Aplicar',
            notifications: {
                updated: 'Informações pessoais atualizadas!',
                country_updated: 'País atualizado!',
            },
            country: 'País'
        },
        my_photos: {
            title: 'Minhas Fotos',
            delete_comment: 'Excluir Comentário',
            warning_delete_comment: 'Você está prestes a excluir este comentário!'
        }
    },
    settings: {
        title: 'Configurações',
        menu: {
            account: 'Conta',
            security_privacy: 'Segurança e Privacidade',
            notifications: 'Notificações',
            contact_us: 'Fale Conosco'
        },
        account: {
            title: 'Conta',
            placeholders: {
                name: 'Nome',
                lastname: 'Sobrenome',
                email: 'Email',
                gender: 'Gênero',
                language: 'Idioma',
                id_profile: 'ID do Perfil',
                postal_code: 'CEP',
                delete_account: 'Excluir Conta',
                deactivate_account: 'Desativar Conta'
            },
            apply: 'Aplicar',
            delete_account_warning: 'Você está prestes a excluir sua conta!',
            delete_account_question: 'Tem CERTEZA ABSOLUTA de que deseja excluí-la?',
            delete_account_confirm: 'Sim, excluir minha conta!',
            notifications: {
                updated: 'Conta atualizada!'
            }
        },
        security: {
            title: 'Segurança e Privacidade',
            placeholders: {
                change_password: 'Alterar Senha',
                password: 'Senha',
                old_password: 'Senha Antiga',
                password_confirmation: 'Confirmação da Senha',
                security_question: 'Pergunta de Segurança',
                question: 'Pergunta',
                answer: 'Resposta',
                input_question: 'Qual era o nome do meu primeiro animal de estimação?',
                input_answer: 'pluto',
                blocked_profiles: 'Perfis Bloqueados',
                unblock: 'Desbloquear',
                hide_profile: 'Ocultar Perfil'
            },
            notifications: {
                password_updated: 'Senha Atualizada!',
                question_updated: 'Pergunta de Segurança Atualizada!',
                updated: 'Segurança e Privacidade atualizadas!',
                unblocked: 'Usuário desbloqueado!'
            }
        },
        notifications: {
            title: 'Envie-me um e-mail quando',
            placeholders: {
                send_me_a_gift: 'Me enviar um presente',
                favorite_member_sign_in: 'Um membro favorito entrar',
                someone_visit_my_profile: 'Alguém visitar meu perfil',
                a_new_member_in_my_area_login: 'Um novo membro na minha área entrar',
                special_offers: 'Ofertas especiais'
            },
            notifications: {
                updated: 'Notificações atualizadas!'
            }
        },
        contact: {
            title: 'Fale Conosco',
            placeholders: {
                email_us: 'Envie-nos um e-mail',
                start_chat: 'Iniciar chat',
                subject: 'Assunto',
                message: 'Mensagem',
                subject_example: 'Tenho problemas com o método de pagamento para créditos',
                message_example: 'Não consigo comprar créditos porque meu cartão não passa...'
            },
            notifications: {
                message_sent: 'A mensagem foi enviada!',
                contact_with_you: 'Por favor, aguarde. Entraremos em contato em breve!'
            }
        }
    },
    videocall: {
        end_call: 'Encerrar Chamada',
        calling: 'Ligando',
        timeout: 'Tempo de Chamada Esgotado!',
        question: 'Você recebeu uma chamada de: {name}. Deseja atender?',
        user_ended_call: '{name} encerrou a chamada de vídeo...',
        user_declined_call: '{name} recusou o pedido de chamada de vídeo...',
        user_is_in_videocall: '{name} já está em uma chamada de vídeo...',
        user_cancel_videocall: '{name} cancelou a chamada de vídeo...',
        note: 'Nota',
        need_more_credit_error: 'Você não tem créditos suficientes para a chamada de vídeo',
        answer: 'Atender',
        cancel: 'Cancelar',
        reject: 'Recusar',
        calling_from: 'Ligando de {name}',
        charged: 'Cobrado {money} créditos.',
        user_disconnected: 'Desconexão do outro usuário...',
        connection_failed: 'Falha na conexão...',
        user_to_need_more_credit_error: '{name} não tem créditos suficientes para a chamada de vídeo',
    },
    countdown: {
        time_over: 'Tempo Esgotado'
    },
    legal: {
        terms: 'Termos de Serviço',
        privacy: 'Política de Privacidade'
    },
    wenjoy: {
        first_name: 'Nome',
        last_name: 'Sobrenome',
        email: 'Email',
        cellphone: 'Telefone',
        document_type: 'Tipo de Documento',
        document_number: 'Número do Documento',
        pay: 'Pagar',
        document_type_options: {
            citizenship_card: 'Carteira de Identidade',
            tax_identification_number: 'Número de Identificação Fiscal (NIT)',
            foreigner_id: 'Documento de Estrangeiro',
            passport: 'Passaporte'
        },
        thanks_for_your_purchase: 'Obrigado pela sua compra!',
        enjoy_your_credits: 'Aproveite seus créditos!',
        enjoy_your_membership: 'Aproveite sua assinatura!',
        back_to_credits: 'Voltar para créditos',
        back_to_memberships: 'Voltar para assinaturas',
        back_to_news: 'Voltar para notícias'
    },
    activities: {
        gift: '{name} enviou um presente para você',
        profile_view: '{name} visualizou seu perfil',
        profile_view_hidden: 'Um usuário visualizou seu perfil',
        photo_comment: '{name} comentou na sua foto',
        photo_like: '{name} curtiu sua foto',
        favorite: '{name} começou a seguir você'
    }
}
