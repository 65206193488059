import { findIndex, forEach, omit } from 'lodash-es'

export default {
    namespaced: true,
    state: {
        gifts: [],
        pagination: null,
        loading: false,
        gift: null,
        categories: [],
        selected_category: null,
        gifted_user: null,
        text_to_user: '',
        // Store
        store_gifts: [],
        store_gifts_by_category: [],
        store_gifts_by_category_pagination: null,
        store_gifted_user: null,
        store_text_to_user: '',
        store_gift: null,
        store_favorite_users: [],
        store_my_gift: null,
        store_search: '',
        store_users: [],
        store_selected_user: null
    },
    mutations: {
        setGifts: (state, payload) => state.gifts = payload,
        pushGifts: (state, payload) => forEach(payload, p => { state.gifts.push(p) }),
        setPagination: (state, payload) => state.pagination = omit(payload, ['data']),
        setLoading: (state, payload) => state.loading = payload,
        setGift: (state, payload) => state.gift = payload,
        setCategories: (state, payload) => state.categories = payload,
        setSelectedCategory: (state, payload) => state.selected_category = payload,
        setGiftedUser: (state, payload) => state.gifted_user = payload,
        setTextToUser: (state, payload) => state.text_to_user = payload,
        resetAll: state => {
            state.gifts = []
            state.pagination = null
            state.gift = null
            state.categories = null
            state.selected_category = null
            state.gifted_user = null
            state.text_to_user = ''
        },
        // Store
        setStoreGifts: (state, payload) => state.store_gifts = payload,
        setStoreGiftsByCategory: (state, payload) => state.store_gifts_by_category = payload,
        setStoreGiftsByCategoryPagination: (state, payload) => state.store_gifts_by_category_pagination = payload,
        setStoreGiftedUser: (state, payload) => state.store_gifted_user = payload,
        setStoreTextToUser: (state, payload) => state.store_text_to_user = payload,
        setStoreGift: (state, payload) => state.store_gift = payload,
        setStoreFavoriteUsers: (state, payload) => state.store_favorite_users = payload,
        cleanStoreValues: state => {
            state.store_gifted_user = null,
            state.store_text_to_user = '',
            state.store_gift = null,
            state.store_favorite_users = []
            state.store_search = ''
            state.store_users = []
            state.store_selected_user = null
        },
        setMyGift: (state, payload) => state.store_my_gift = payload,
        setStoreSearch: (state, payload) => state.store_search = payload,
        setStoreUsers: (state, payload) => state.store_users = payload,
        setStoreSelectedUser: (state, payload) => state.store_selected_user = payload
    },
    getters: {
        getGifts: state => state.gifts,
        getPagination: state => state.pagination,
        getLoading: state => state.loading,
        getGift: state => state.gift,
        getCategories: state => state.categories,
        getSelectedCategory: state => state.selected_category,
        getGiftedUser: state => state.gifted_user,
        getTextToUser: state => state.text_to_user,
        // Store
        getStoreGifts: state => state.store_gifts,
        getStoreGiftsByCategory: state => state.store_gifts_by_category,
        getStoreGiftsByCategoryPagination: state => state.store_gifts_by_category_pagination,
        getStoreGiftedUser: state => state.store_gifted_user,
        getStoreTextToUser: state => state.store_text_to_user,
        getStoreGift: state => state.store_gift,
        getStoreFavoriteUsers: state => state.store_favorite_users,
        getMyGift: state => state.store_my_gift,
        getStoreSearch: state => state.store_search,
        getStoreUsers: state => state.store_users,
        getStoreUserById: state => id_user => {
            let index = findIndex(state.store_users, o => o.id == id_user)
            if (index != -1) { return state.store_users[index] } else { return null }
        },
        getStoreSelectedUser: state => state.store_selected_user
    },
    actions: {
        getCategories: ({ commit }) => {
            commit('setLoading', true)
            return window.axios.get('/gifts/get/categories').then(response => {
                commit('setCategories', response.data)
                commit('setLoading', false)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setLoading', false)
                throw error
            })
        },
        getGifts: ({ commit, getters }, url) => {
            commit('setLoading', true)
            let route = ''
            if (url == null) {
                if (getters.getSelectedCategory == null) { route = '/gifts/get' }
                else { route = '/gifts/category/' + getters.getSelectedCategory }
            } else { route = url }
            return window.axios.get(route).then(response => {
                if (url == null) {
                    commit('setGifts', response.data.data)
                } else {
                    commit('pushGifts', response.data.data)
                }
                commit('setPagination', response.data)
                commit('setLoading', false)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setLoading', false)
                throw error
            })
        },
        sendGift: ({ commit, getters }) => {
            commit('setLoading', true)
            return window.axios.post('/gifts/send', {
                id_user: getters.getGiftedUser.id,
                id_gift: getters.getGift.id,
                message: getters.getTextToUser
            }).then(response => {
                commit('setLoading', false)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setLoading', false)
                throw error
            })
        },
        // Store
        getStoreGifts: ({ commit }) => {
            commit('setLoading', true)
            return window.axios.get('/gifts/store/get').then(response => {
                commit('setStoreGifts', response.data)
                commit('setLoading', false)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setLoading', false)
                throw error
            })
        },
        getStoreGiftsByCategory: ({ commit }, params) => {
            commit('setLoading', true)
            let route = ''
            if (params.url == null) { route = '/gifts/store/category/' + params.id_category }
            else { route = params.url }
            return window.axios.get(route).then(response => {
                commit('setStoreGiftsByCategory', response.data.data)
                commit('setStoreGiftsByCategoryPagination', response.data)
                commit('setLoading', false)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setLoading', false)
                throw error
            })
        },
        sendStoreGift: ({ commit, getters }) => {
            commit('setLoading', true)
            return window.axios.post('/gifts/store/send', {
                id_user: getters.getStoreGiftedUser.id,
                id_gift: getters.getStoreGift.id,
                message: getters.getStoreTextToUser
            }).then(response => {
                commit('setLoading', false)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setLoading', false)
                throw error
            })
        },
        getStoreGift: ({ commit }, id_gift) => {
            commit('setLoading', true)
            return window.axios.get('/gifts/store/get/' + id_gift).then(response => {
                commit('setStoreGift', response.data)
                commit('setLoading', false)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setLoading', false)
                throw error
            })
        },
        getStoreFavoriteUsers: ({ commit }) => {
            commit('setLoading', true)
            return window.axios.get('/favorites/store/get').then(response => {
                commit('setStoreFavoriteUsers', response.data)
                commit('setLoading', false)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setLoading', false)
                throw error
            })
        },
        getOpenedMyGift: ({ commit }, id_user_gift) => {
            commit('setLoading', true)
            return window.axios.get('/gifts/store/open/' + id_user_gift).then(response => {
                commit('setMyGift', response.data)
                commit('setLoading', false)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setLoading', false)
                throw error
            })
        },
        getSearchUsers: ({ commit, getters }) => {
            commit('setLoading', true)
            return window.axios.get('/gifts/store/users/search', {
                params: { search: getters.getStoreSearch }
            }).then(response => {
                commit('setStoreUsers', response.data)
                commit('setLoading', false)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setLoading', false)
                throw error
            })
        }
    }
}
