const BaseModule = () => import('../../components/basemodule.vue')
const Index = () => import('./index.vue')
const Finished = () =>import('./finished.vue')

export default [
    {
        path: '/credits',
        component: BaseModule,
        name: 'Credits',
        redirect: '/credits/index',
        children: [
            {
                name: 'Buy More Credits',
                path: 'index',
                component: Index,
                meta: { requireBeApproved: true, notSuspendedUser: true }
            },
            {
                name: 'Finished Credits Payment',
                path: 'finished',
                component: Finished,
                meta: { requireBeApproved: true, notSuspendedUser: true }
            }
        ]
    }
]
