import Vue from "vue";
import Vuex from "vuex";

import { events } from './messages-events';

// Modulos
import components from "./components/store";
import posts from "./modules/posts/store";
import chat from "./modules/chat/store";
import meeting from "./modules/meeting/store";
import gifts from "./modules/gifts/store";
import profile from "./modules/profile/store";
import profiles from "./modules/profiles/store";
import settings from "./modules/settings/store";
import activities from "./modules/activities/store";
import notifications from "./modules/notifications/store";
import videocall from "./modules/videocall/store";
import { setZoneScrollToBottom } from "./helpers";
Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        components,
        posts,
        chat,
        meeting,
        gifts,
        profile,
        profiles,
        settings,
        activities,
        notifications,
        videocall,
    },
    state: {
        socket: {
            isConnected: false,
            message: "",
            reconnectError: false,
        },
    },
    mutations: {
        SOCKET_ONOPEN(state, event) {
            Vue.prototype.$socket = event.currentTarget;
            state.socket.isConnected = true;
            if (window.app.$socket.readyState === 1) {
                window.app.$socket.send(
                    JSON.stringify({
                        action: events.SET_USER_ID,
                        userId: state.components.user_data.id,
                        typeUser: state.components.user_data.type,
                    })
                );
                // Send ping action every 30 seconds
                setInterval(() => {
                    if(window.app.$socket.readyState === 1) {
                        window.app.$socket.send(JSON.stringify({
                            action: events.PING,
                            userId: state.components.user_data.id,
                        }));
                    }
                }, 30000)
            }
        },
        SOCKET_ONCLOSE(state, event) {
            state.socket.isConnected = false;
        },
        SOCKET_ONERROR(state, event) {
            console.error(state, event);
        },
        // default handler called for all methods
        SOCKET_ONMESSAGE(state, message) {
            state.socket.message = message;
            if (message.event === events.RECEIVE_MESSAGE) {
                if (state.chat.room === message.room) {
                    state.chat.messages.push(message);
                    Vue.nextTick(() => {
                        setZoneScrollToBottom("#chatzone");
                    });
                }
                const indexConversation = state.chat.conversations.findIndex(
                    (value) => value.room === message.room
                );
                if (indexConversation !== -1) {
                    state.chat.conversations[indexConversation].last_message =
                        message.message;
                    state.chat.conversations[indexConversation].unread_count =
                        parseInt(
                            state.chat.conversations[indexConversation]
                                .unread_count
                        ) + 1;
                    state.chat.conversations[indexConversation].updatedAt =
                        new Date().toISOString();
                    state.components.updated_conversations_on = true;
                }
                if (indexConversation === -1) {
                    state.chat.conversations.push({
                        _id: message.conversation.id_conversation,
                        last_message: message.conversation.last_message,
                        user: message.conversation.user_to,
                        user_to: message.conversation.user,
                        id_user: message.conversation.id_user_to,
                        id_user_to: message.conversation.id_user,
                        room: message.conversation.room,
                        unread_count: 1,
                        updatedAt: new Date().toISOString(),
                        createdAt: new Date().toISOString(),
                    });
                    state.components.updated_conversations_on = true
                }
            }

            if (message.event === events.USER_TYPING) {
                if (message.userId === state.chat.user_to.id) {
                    state.chat.is_user_typing = message.state;
                }
            }

            if(message.event === events.PONG) {
                console.log('Received pong from server.')
            }
        },
        // mutations for reconnect methods
        SOCKET_RECONNECT(state, count) {
            //
        },
        SOCKET_RECONNECT_ERROR(state) {
            state.socket.reconnectError = true;
        },
    },
});

export default store;
