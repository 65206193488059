const Base = () => import('./base.vue')
const GiftStore = () => import('./giftstore.vue')
const Gifted = () => import('./gifted.vue')
const Opening = () => import('./opening.vue')

export default [
    {
        path: '/gifts',
        component: Base,
        name: 'Gifts',
        redirect: '/gifts/store',
        children: [
            {
                name: 'Gifts Store',
                path: 'store',
                component: GiftStore,
                meta: { requireBeApproved: true, notTimeOut: true, notSuspendedUser: true }
            },
            {
                name: 'Gifted User',
                path: 'gifted/:id',
                component: Gifted,
                meta: {
                    requiresActiveMembership: true,
                    requireBeApproved: true,
                    notTimeOut: true,
                    notSuspendedUser: true
                }
            },
            {
                name: 'Opening Gift',
                path: 'opening/:id',
                component: Opening,
                meta: { requireBeApproved: true, notTimeOut: true, notSuspendedUser: true }
            }
        ]
    }
]
