const BaseModule = () => import('../../components/basemodule.vue')
const Index = () => import('./index.vue')

export default [
    {
        path: '/meeting',
        component: BaseModule,
        name: 'Meeting',
        redirect: '/meeting/index',
        children: [
            {
                name: 'Match Search',
                path: 'index',
                component: Index,
                meta: { requireBeApproved: true, notTimeOut: true, notSuspendedUser: true }
            }
        ]
    }
]
