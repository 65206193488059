const BaseModule = () => import('../../components/basemodule.vue')
const Index = () => import('./index.vue')
const Photo = () => import('./photo.vue')

export default [
    {
        path: '/profile',
        component: BaseModule,
        name: 'Profile',
        redirect: '/profile/index',
        children: [
            {
                name: 'My Profile',
                path: 'index',
                component: Index
            },
            {
                name: 'My Photo',
                path: 'photo/:id',
                component: Photo
            }
        ]
    }
]
