import { findIndex, forEach, omit } from 'lodash-es'

export default {
    namespaced: true,
    state: {
        notifications: [],
        pagination: null,
        loading: false,
        unread_count: 0,
        is_open_notification_panel: false
    },
    mutations: {
        setNotifications: (state, payload) => state.notifications = payload,
        setPagination: (state, payload) => state.pagination = omit(payload, ['data']),
        addNotifications: (state, payload) => forEach(payload, p => { state.notifications.push(p) }),
        addNotification: (state, payload) => {
            state.notifications.unshift(payload)
            state.unread_count++
        },
        setLoading: (state, payload) => state.loading = payload,
        setUnreadCount: (state, payload) => state.unread_count = payload,
        plusUnreadCount: state => state.unread_count++,
        setIsOpenNotificatonPanel: (state, payload) => state.is_open_notification_panel = payload
    },
    getters: {
        getNotifications: state => state.notifications,
        getPagination: state => state.pagination,
        getNotificationById: state => id_notification => {
            let index = findIndex(state.notifications, o => o.id == id_notification)
            if (index != -1) { return state.notifications[index] } else { return null }
        },
        getLoading: state => state.loading,
        getUnreadCount: state => state.unread_count,
        getIsOpenNotificatonPanel: state => state.is_open_notification_panel
    },
    actions: {
        getNotifications: ({ commit, getters }, url) => {
            commit('setLoading', true)
            let route = '/notifications/get'
            if (url != null) { route = url }
            return window.axios.get(route).then(response => {
                if (getters.getNotifications.length != 0) {
                    commit('addNotifications', response.data.data)
                } else {
                    commit('setNotifications', response.data.data)
                }
                commit('setPagination', response.data)
                commit('setLoading', false)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setLoading', false)
                throw error
            })
        },
        addOrCountNotification: ({ commit, getters }, notification) => {
            if (getters.getIsOpenNotificatonPanel) {
                commit('addNotification', notification)
            } else {
                commit('plusUnreadCount')
            }
        }
    }
}
