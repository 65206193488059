export default {
    publicUrl: document.head.querySelector('meta[name="public_url"]').getAttribute('content'),
    appName: document.head.querySelector('meta[name="app_name"]').getAttribute('content'),
    appUrl: document.head.querySelector('meta[name="app_url"]').getAttribute('content'),
    appBaseUrl: document.head.querySelector('meta[name="app_base_url"]').getAttribute('content'),
    maxFileSize: document.head.querySelector('meta[name="max_file_size"]').getAttribute('content'),
    avatar: {
        default: document.head.querySelector('meta[name="app_default_avatar"]').getAttribute('content'),
        hidden: document.head.querySelector('meta[name="app_hidden_avatar"]').getAttribute('content')
    },
    previewImage: document.head.querySelector('meta[name="app_preview_image"]').getAttribute('content'),
    token: document.head.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    pusher: {
        key: document.head.querySelector('meta[name="pusher_key"]').getAttribute('content'),
        cluster: document.head.querySelector('meta[name="pusher_cluster"]').getAttribute('content')
    },
    mapbox: {
        token: document.head.querySelector('meta[name="mapbox_token"]').getAttribute('content')
    },
    paypalClientId: document.head.querySelector('meta[name="paypal_client_id"]').getAttribute('content')
}