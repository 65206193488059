import { forEach, omit, findIndex } from 'lodash-es'

export default {
    namespaced: true,
    state: {
        posts: [],
        pagination: null,
        famous: [],
        loadingPosts: false,
        loadingFamous: false
    },
    mutations: {
        setPosts: (state, payload) => forEach(payload, p => {state.posts.push(p) }),
        setPagination: (state, payload) => state.pagination = omit(payload, ['data']),
        setLoadingPosts: (state, payload) => state.loadingPosts = payload,
        setFamous: (state, payload) => state.famous = payload,
        setLoadingFamous: (state, payload) => state.loadingFamous = payload,
        clean: state => {
            state.posts = []
            state.famous = []
            state.pagination = null
        }
    },
    getters: {
        getPosts: state => state.posts,
        getPagination: state => state.pagination,
        getLoadingPosts: state => state.loadingPosts,
        getFamous: state => state.famous,
        getLoadingFamous: state => state.loadingFamous,
        getPostById: state => id_post => {
            let index = findIndex(state.posts, o => o.id == id_post)
            if (index != -1) { return state.posts[index] } else { return null }
        }
    },
    actions: {
        getPosts: ({ commit }, url) => {
            commit('setLoadingPosts', true)
            let route = '/posts/get'
            if (url != null) { route = url }
            return window.axios.get(route).then(response => {
                commit('setPosts', response.data.data)
                commit('setPagination', response.data)
                commit('setLoadingPosts', false)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setLoadingPosts', false)
                throw error
            })
        },
        getFamous: ({ commit }) => {
            commit('setLoadingFamous', true)
            return window.axios.get('/posts/famous/get').then(response => {
                commit('setFamous', response.data)
                commit('setLoadingFamous', false)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setLoadingFamous', false)
                throw error
            })
        }
    }
}
