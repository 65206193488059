const Base = () => import('./base.vue')
const List = () => import('./list.vue')
const Profile = () => import('./profile.vue')

export default [
    {
        path: '/profiles',
        component: Base,
        name: 'Profiles',
        redirect: '/profiles/new',
        children: [
            {
                name: 'Featured Profiles',
                path: 'featured',
                component: List,
                meta: { requireBeApproved: true, notTimeOut: true, notSuspendedUser: true }
            },
            {
                name: 'New Profiles',
                path: 'new',
                component: List,
                meta: { requireBeApproved: true, notTimeOut: true, notSuspendedUser: true }
            },
            {
                name: 'Profile View',
                path: 'profile/:id',
                component: Profile,
                meta: { requireBeApproved: true, notTimeOut: true, notSuspendedUser: true }
            }
        ]
    }
]
