import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'

import {
    faArrowRight, faArrowLeft, faBars, faTimesCircle, faUserCircle,
    faSignOutAlt, faCogs, faPhoneSlash, faVideoSlash, faMicrophoneSlash,
    faExpand, faPhone, faVideo, faMicrophone, faUsers, faArrowCircleDown,
    faTrash, faThumbsUp, faComment, faSearch, faChevronUp, faChevronDown, faTimes, faMoon
} from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
    faArrowRight, faArrowLeft, faBars, faTimesCircle, faUserCircle,
    faSignOutAlt, faCogs, faPhoneSlash, faVideoSlash, faMicrophoneSlash,
    faExpand, faPhone, faVideo, faMicrophone, faUsers, faArrowCircleDown,
    faTrash, faThumbsUp, faComment, faSearch, faChevronUp, faChevronDown, faTimes, faMoon
)

Vue.component('font-awesome-icon', FontAwesomeIcon)


