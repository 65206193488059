export default {
    message: {
        hello: 'hola mundo'
    },
    test: 'prueba en español',
    inputs: {
        no: 'No',
        yes: 'Si',
        remove: 'Quitar',
        search: 'Buscar',
        return: 'Regresar',
        comment: 'Comentar',
        delete: 'Borrar',
        cancel: 'Cancelar',
        all: 'Todos',
        button: {
            apply: 'Aplicar'
        },
        select: {
            default_name_value_text: 'Selecciona una opción'
        },
        notifications: {
            notification: 'Notificación',
            errors: 'Hay algunos errores...',
            server: 'Error del servidor. Por favor trate despues de un tiempo...',
            deleted: 'Borrado!',
            updated: 'Actualizado!',
            created: 'Creado!'
        },
        pagination: {
            next: 'Siguiente',
            previous: 'Anterior',
            load_more: 'Cargar más'
        },
        image_upload_errors: {
            size: 'La imagen no debe ser mayor a 10MB',
            format: 'El archivo debe ser un archivo de imagen',
            required: 'La imagen es requerida',
            server_error: 'Error de servidor. Por favor trate otra vez despues...'
        }
    },
    modules: {
        news: 'Noticias',
        chat: 'Chat',
        meeting: 'Haz Match',
        store: 'Tienda',
        featured_profiles: 'Perfiles Destacados',
        new_members: 'Nuevos Miembros',
        credits: 'Créditos',
        memberships: 'Membresías',
        profile: 'Perfil',
        settings: 'Configuración'
    },
    menu: {
        match: 'Match',
        chat: 'Chat',
        news: 'Noticias',
        credits: 'Créditos',
        memberships: 'Membresías',
        gift_store: 'Tienda de Regalos',
        new_profiles: 'Nuevos Miembros',
        featured_profiles: 'Perfiles Destacados',
    },
    news: {
        messages: {
            avatar: '{name} tiene un nuevo avatar!',
            membership: '{name} tiene una nueva membresía!',
            photo: '{name} ha subido una foto nueva!'
        }
    },
    meeting: {
        filters_title: 'Filtros de Búsqueda',
        name: 'Nombre de usuario',
        age_range: 'Rango de Edad',
        city: 'Ciudad',
        distance_range: 'Rango de Distancia',
        search_for: 'Buscar Por',
        with_photos: 'Con Fotos',
        new_members: 'Miembros Nuevos',
        featured_members: 'Miembros Destacados',
        body_type: 'Tipo de Cuerpo',
        search_limits: 'Limites',
        clean_fields: 'Limpiar Campos',
        apply: 'Aplicar',
        say_hi: 'Di Hola!',
        send_gift: 'Enviar Regalo!',
        filters: 'Filtros',
        memberships: 'Membresías',
        all_search_for: 'Todos los generos',
        all_body_type: 'Todos los tipos de cuerpo',
        all_search_limits: 'Todos los limites',
        no_results: 'No hay resultados...',
        search_by_user_name: "Buscar por el nombre del usuario",
        no_photos: 'El usuario no tiene fotos...'
    },
    chat: {
        search_message: 'Buscar un mensajes',
        results: 'resultados',
        title_mini: 'Chat',
        add_to_favorites: 'Agregar a Favoritos',
        remove_from_favorites: 'Quitar de Favoritos',
        send_gift: 'Enviar Regalos',
        block_user: 'Bloquear Usuario',
        unblock_user: 'Desbloquear Usuario',
        delete_conversation: 'Borrar Conversación',
        search_conversation: 'Buscar Conversación...',
        conversations: 'Conversaciones',
        load_more: 'Cargar Más',
        type_message: 'Escribe un mensaje...',
        quote: 'Citar',
        delete: 'Borrar',
        user_to_typing: ' esta escribiendo...',
        there_are_no_conversations: 'No hay conversaciones...',
        start_chatting_users: 'Empieza a conversar con estos usuarios',
        image_upload_errors: {
            size: 'La imagen no debe ser mayor a 2MB',
            format: 'El archivo debe ser un archivo de imagen',
            required: 'La imagen es requerida',
            server_error: 'Error de servidor. Por favor trate otra vez despues...'
        },
        notifications: {
            added_to_favorites: '¡El usuario ha sido agregado a tus favoritos!',
            remove_from_favorites: '¡El usuario ha sido eliminado de tus favoritos!',
            user_blocked: '¡Usuario Bloqueado!',
            user_unblocked: '¡Usuario Desbloquear!',
            gift_sent_to_user: '¡El regalo ha sido enviado al usuario seleccionado!',
            title_delete_conversation: 'Borrar Conversación',
            delete_it: 'Borrala',
            cancel: 'Cancelar',
            sure_to_delete_conversation: '¿Estás seguro de que deseas eliminar la conversación?',
            conversation_deleted: '¡La conversación ha sido eliminada!'
        },
        emoji: {
            search: 'Buscar',
            notfound: 'No se ha encontrado ningún emoji',
            categories: {
                search: 'Resultados de la búsqueda',
                recent: 'Usado frecuentemente',
                people: 'Sonrisas & Personas',
                nature: 'Animales & Naturaleza',
                foods: 'Comida & Bebidas',
                activity: 'Actividad',
                places: 'Viaje & Lugares',
                objects: 'Objectos',
                symbols: 'Simbolos',
                flags: 'Banderas',
                custom: 'Personalizado'
            }
        }
    },
    gifts: {
        all: 'Todos',
        sending_to: 'Estás enviando este regalo a <b class="title_red">{name}</b>',
        write_to: 'Escribe algo agradable',
        send_gift: 'Enviar regalo',
        cancel: 'Cancelar',
        load_more: 'Cargar Más',
        search_user: 'Buscar usuario...',
        notifications: {
            gift_sent_to: 'Regalo enviado a {name}',
            server_error: 'Error de servidor. Por favor trate otra vez despues...',
            need_more_credits: '¡Necesitas más créditos! Compre más haciendo clic <strong>aquí!</strong>',
            please_select_user: 'Por favor selecciona el usuario...',
            plase_fill_message: 'Por favor envíe un mensaje al usuario.'
        },
        opening: {
            congratulations: 'Felicidades! Tienes un nuevo regalo!',
            from: 'De',
            message: 'Mensaje'
        },
        title: 'Tienda',
        giveaway: 'Regalar',
        send_to: 'Enviar a',
        chat_with: 'Conversar con {name}',
        back_to_store: 'Regresar a la tienda',
        go_to_profile: 'Ir al perfil de {name}',
        back_to_my_profile: 'Ir a mi perfil',
        activities: {
            you_have_a_new_gift: 'Tienes un nuevo regalo!',
            sent_you_gift: '{name} te ha enviado un regalo'
        }
    },
    profiles: {
        title: 'Perfiles',
        featured_profiles: 'Perfiles Destacados',
        new_profiles: 'Perfiles Nuevos',
        distance: '{number} km de distancia',
        description_title: 'Sobre {name}',
        interests_title: 'Intereses',
        like: 'Me Gusta',
        comment: 'Comentar',
        write_something_input: 'Escribe algo sobre su foto',
        load_more: 'Ver más comentarios',
        activities: {
            new_comment: 'Nuevo Comentario',
            new_comment_photo: 'Tienes un comentario nuevo en una foto',
            new_like: 'Nuevo Me Gusta',
            new_like_photo: 'Tienes un me gusta nuevo en tu foto',
            new_view: 'Nueva visita al perfil!',
            new_view_user: '{name} ha visto tu perfil!',
            new_view_hidden: 'Un usuario ha visto tu perfil!'
        },
        information: {
            title: 'Información personal',
            relation: 'Relación',
            sexual_orientation: 'Orientación sexual',
            body_type: 'Tipo de cuerpo',
            i_live_with: 'Vivo con',
            eye_color: 'Color de los ojos',
            hair_color: 'Color de pelo',
            drink: 'Bebida',
            tobacco: 'Tabaco',
            show: 'Mostrar',
            country: 'País',
        }
    },
    memberships: {
        please_select: 'Por favor, Selecciona tu membresía',
        days: '{number} Días',
        minutes: '{number} Minutos',
        contains: 'Ver qué contiene',
        payment_method: 'Método de Pago',
        popular: 'Popular',
        free: 'Free',
        pricing_text: '${price} USD',
        i_want_this: 'Quiero este',
        you_are_going_to_buy: 'Vas a comprar',
        for: 'Por {money}',
        containers: {
            start_chat_dialog: 'Iniciar diálogo de chat',
            read_chat_messages: 'Leer mensajes de chat',
            continue_chat_dialog: 'Continuar diálogo de chat',
            to_see_photos: 'Ver fotos',
            upload_photos: 'Subir fotos',
            comment_photos: 'Comentar fotos',
            allow_posts_on_profile_wall: 'Permitir posts en el muro de perfil',
            search_users: 'Buscar usuarios',
            see_profiles: 'Ver Perfiles',
            send_presents: 'Enviar regalos',
            add_to_favorites: 'Añadir a favoritos',
            to_call: 'Llamar',
            answer: 'Responder',
            change_preferences: 'Cambiar preferencias',
            allow_comment_status_updates: 'Permitir actualizaciones de estado de comentarios',
            allow_status_updates: 'Permitir actualizaciones de estado'
        },
        notifications: {
            has_not_expired: 'Su membresía actual no ha expirado',
            want_new_one: '¿Quieres comprar una nueva?',
            success: 'Gracias por tu compra, ¡tu membresía ha sido actualizada!',
            buy_canceled: 'Compra de membresía cancelada!',
            membership_expired: 'Tu membresía ha expirado!'
        }
    },
    credits: {
        please_select: 'Por favor, Selecciona tu paquete de monedas',
        credits: '{number} Créditos',
        payment_method: 'Método de Pago',
        popular: 'Popular',
        you_are_going_to_buy: 'Vas a comprar',
        for: 'Por {money}',
        notifications: {
            success: 'Gracias por tu compra, tus creditos se han añadido a tu cartera.',
            buy_canceled: 'Compra de creditos cancelada!'
        }
    },
    user_menu: {
        logout: 'Cerrar sesión',
        profile: 'Perfil',
        settings: 'Configuración',
        install_pwa: 'Instalar Aplicación PWA',
        copy_referred_link: 'Copiar link de referido',
        notifications: {
            copied: 'Enlace copiado correctamente',
        },
        pwa_available: 'Instala nuestra aplicación gratuita. No ocupará mucho espacio en tu dispositivo',
        install: 'Instalar',
        dark_mode: {
            title: 'Modo oscuro',
            configuration: {
                system: 'Automático (Sistema)',
                enabled: 'Activado',
                disabled: 'Desactivado'
            }
        }
    },
    pay_shortcuts: {
        memberships: 'Membresías',
        credits: 'Créditos',
    },
    online: {
        favorite_online: '{name} se ha conectado!'
    },
    favorites: {
        title: 'Favoritos',
        no_favorites_added: 'No se han agregado favoritos...',
        activities: {
            new_favorite: 'Nuevo Favorito',
            new_favorite_user: 'Un usuario te ha seguido'
        }
    },
    online_users: {
        title: 'Usuarios en línea',
        there_are_no_users_online: 'No hay usuarios en línea...'
    },
    profile: {
        title: 'Perfil',
        placeholders: {
            email: 'mymail@gmail.com',
            phone: '6121366758',
            birthday: 'Nacimiento',
            gender: 'Género'
        },
        member_since: 'Miembro desde ',
        add_photo: 'Agregar Foto',
        image_upload_errors: {
            size: 'La imagen no debe ser mayor a 2MB',
            format: 'El archivo debe ser un archivo de imagen',
            required: 'La imagen es requerida',
            server_error: 'Error de servidor. Por favor trate otra vez despues...'
        },
        delete_photo_modal_title: 'Borrando Foto',
        delete_photo_modal_text: 'Vas a borrar a esta foto',
        cancel: 'Cancelar',
        delete_it: 'Borrala!',
        notifications: {
            image_uploaded: '¡La imagen ha sido agregada!',
            photo_deleted: '¡Foto eliminada!',
            updated_personal: '¡Información personal del usuario actualizada!',
            user_not_approved: 'Su usuario esta en proceso de ser verificado, mientras tanto y si lo desea puede ir llenando sus datos personas para darse a conocer ante los demas. Gracias por su paciencia.',
            user_time_out: 'Su tiempo gratuito ha terminado. Si desea seguir en la aplicacion por favor compre una membresia.',
            user_suspended: 'Tu cuenta ha sido suspendida, puede consultar en soporte cual ah sido el motivo.'
        },
        pagination: {
            next: 'Siguiente',
            previous: 'Anterior'
        },
        gifts: {
            tab1: 'Regalos recibidos',
            tab2: 'Regalos enviados',
            sent_by_name: 'Regalo enviado por {name}',
            recived_date: 'Recibido {date}',
            sent_to_name: 'Regalo enviado a {name}',
            delete_gift_modal_title: 'Eliminando regalo',
            delete_gift_modal_text: '¡Vas a eliminar este regalo!',
            cancel: 'Cancelar',
            delete_it: 'Borralo!',
            notifications: {
                removed: '¡Interés removido!'
            }
        },
        complete_profile: 'Completar Perfil',
        myprogress: {
            errors: {
                full_name: "Cambiar el nombre completo del usuario",
                phone: 'Agregar número de teléfono',
                photos: 'Agregar al menos 5 fotos',
                avatar: 'Cambiar el avatar',
                locaiton: 'Actualizar tu ubicación',
                interests: 'Agregar al menos 4 intereses',
                information: 'Actualiza tu información personal'
            }
        },
        location: {
            title: 'Ubicación',
            update: 'Actualizar Ubicación',
            get_current: 'Obtener la ubicación actual',
            notifications: {
                updated: '¡La ubicación ha sido actualizada!'
            }
        },
        interests: {
            title: 'Intereses',
            close: 'Cerrar',
            load_more: 'Cargar Más',
            more_popular: 'Intereses más populares',
            delete_interest_modal_title: 'Eliminar interés',
            delete_interest_modal_text: '¡Vas a eliminar este interés!',
            cancel: 'Cancelar',
            delete_it: 'Borralo!',
            notifications: {
                added: '¡Interés agregado!',
                removed: '¡Interés removido!'
            }
        },
        searching_for: {
            title: 'Busco',
            default_search_for_text: 'Busco',
            default_search_limits_text: 'Limites'
        },
        personal_information: {
            title: 'Información personal',
            biography_placeholder: 'Escribe sobre ti, ¡una descripción interesante!',
            relation: 'Relación',
            sexual_orientation: 'Orientación sexual',
            body_type: 'Tipo de cuerpo',
            i_live_with: 'Vivo con',
            eye_color: 'Color de los ojos',
            hair_color: 'Color de pelo',
            drink: 'Bebida',
            tobacco: 'Tabaco',
            clean_fields: 'Limpiar Campos',
            apply: 'Aplicar',
            notifications: {
                updated: '¡Información personal actualizada!',
                country_updated: '¡Pais actualizado!',
            },
            country: 'País',
        },
        my_photos: {
            title: 'Mis Fotos',
            delete_comment: 'Borrar Comentario',
            warning_delete_comment: '¡Vas a eliminar este comentario!'
        }
    },
    settings: {
        title: 'Configuración',
        menu: {
            account: 'Cuenta',
            security_privacy: 'Seguridad y Privacidad',
            notifications: 'Notificaciones',
            contact_us: 'Contactanos'
        },
        account: {
            title: 'Cuenta',
            placeholders: {
                name: 'Nombre',
                lastname: 'Apellido',
                email: 'Correo',
                gender: 'Género',
                language: 'Idioma',
                id_profile: 'ID Perfil',
                postal_code: 'Código postal',
                delete_account: 'Borrar cuenta',
                deactivate_account: 'Desactivar cuenta'
            },
            apply: 'Aplicar',
            delete_account_warning: '¡Vas a borrar tu cuenta!',
            delete_account_question: '¿Estás ABSOLUTAMENTE SEGURO de que deseas eliminarlo?',
            delete_account_confirm: '¡Sí, borra mi cuenta!',
            notifications: {
                updated: 'Cuenta actualizada!'
            }
        },
        security: {
            title: 'Seguridad y Privacidad',
            placeholders: {
                change_password: 'Cambiar la contraseña',
                password: 'Contraseña',
                old_password: 'Contraseña anterior',
                password_confirmation: 'Confirmación de contraseña',
                security_question: 'Pregunta de seguridad',
                question: 'Pregunta',
                answer: 'Respuesta',
                input_question: '¿Cómo se llamaba mi primera mascota?',
                input_answer: 'pluto',
                blocked_profiles: 'Perfiles bloqueados',
                unblock: 'Desbloquear',
                hide_profile: 'Ocultar perfil'
            },
            notifications: {
                password_updated: '¡Contraseña actualiza!',
                question_updated: 'Pregunta de seguridad actualizada.',
                updated: '¡Seguridad y privacidad actualizada!',
                unblocked: 'Usuario desbloqueado!'
            }
        },
        notifications: {
            title: 'Envíame un correo electrónico cuando',
            placeholders: {
                send_me_a_gift: 'Envíame un regalo',
                favorite_member_sign_in: 'Un miembro favorito inicia sesión',
                someone_visit_my_profile: 'Alguien visita mi perfil',
                a_new_member_in_my_area_login: 'Un nuevo miembro en mi área inicia sesión',
                special_offers: 'Ofertas especiales'
            },
            notifications: {
                updated: '¡Notificaciones actualizadas!'
            }
        },
        contact: {
            title: 'Contactanos',
            placeholders: {
                email_us: 'Envíanos un correo electrónico',
                start_chat: 'Comenzar chat',
                subject: 'Asunto',
                message: 'Mensaje',
                subject_example: 'Tengo problemas con la forma de pago de los créditos.',
                message_example: 'No puedo comprar créditos porque mi tarjeta no pasa...'
            },
            notifications: {
                message_sent: '¡El mensaje fue enviado!',
                contact_with_you: 'Por favor sea paciente. ¡Nos pondremos en contacto con usted pronto!'
            }
        }
    },
    videocall: {
        end_call: 'Finalizar Llamada',
        calling: 'Llamando',
        timeout: 'Tiempo de espera finalizado!',
        question: 'Tienes una llamada de: {name}. ¿Le gustaría contestar?',
        user_ended_call: '{name} ha finalizado la videollamada...',
        user_declined_call: '{name} ha rechazado la solicitud de videollamada...',
        user_is_in_videocall: '{name} ya esta en una videollamada...',
        user_cancel_videocall: '{name} cancelo la videollamada...',
        note: 'Nota',
        need_more_credit_error: "No tienes suficientes créditos para la videollamada",
        answer: 'Responder',
        cancel: 'Cancelar',
        reject: 'Rechazar',
        calling_from: 'Llamada de {name}',
        charged: 'Cobrado {money} créditos.',
        user_disconnected: 'Desconexión del otro usuario...',
        connection_failed: 'Fallo de conexión...',
        user_to_need_more_credit_error: '{name} no tiene suficientes créditos para la videollamada',
    },
    countdown: {
        time_over: 'Tiempo Acabado'
    },
    legal: {
        terms: 'Términos de Servicio',
        privacy: 'Política de Privacidad'
    },
    wenjoy: {
        first_name: 'Nombre',
        last_name: 'Apellido',
        email: 'Correo',
        cellphone: 'Teléfono',
        document_type: 'Tipo de Documento',
        document_number: 'Número de Documento',
        pay: 'Pagar',
        document_type_options: {
            citizenship_card: 'Cédula de ciudadanía',
            tax_identification_number: 'Número de Identificación Tributaria (NIT)',
            foreigner_id: 'Cédula de extranjería',
            passport: 'Pasaporte'
        },
        thanks_for_your_purchase: 'Gracias por tu compra!',
        enjoy_your_credits: 'Disfruta tus créditos!',
        enjoy_your_membership: 'Disfruta tu membresía!',
        back_to_credits: 'Regresar a créditos',
        back_to_memberships: 'Regresar a membresías',
        back_to_news: 'Regresar a noticias'
    },
    activities: {
        gift: '{name} te ha enviado un regalo',
        profile_view: '{name} ha visto tu perfil',
        profile_view_hidden: 'Un usuario ha visto tu perfil',
        photo_comment: '{name}ha comentado tu foto ',
        photo_like: '{name} le gusta tu foto',
        favorite: '{name} te ha seguido'
    }
}
