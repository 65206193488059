import { findIndex } from 'lodash-es'
function defaultSearch() {
    return {
        age_range: [18, 50],
        distance: 5,
        search_for: 'women',
        with_photos: true,
        new_members: true,
        featured_member: true,
        body_type: 'all',
        search_limits: 'all'
    }
}

export default {
    namespaced: true,
    state: {
        users: [], // [4, 67, 78, 21]
        loading: false,
        search: defaultSearch(),
        user: null,
        filterOn: false,
        counter: 0,
        index_user: 0,
        index_photo: 0,
        up: true
    },
    mutations: {
        setSideBarOn: (state, payload) => state.up = payload,
        setUsers: (state, payload) => state.users = payload,
        setLoading: (state, payload) => state.loading = payload,
        setUser: (state, payload) => state.user = payload,
        setFilterOn: (state, payload) => state.filterOn = payload,
        setIndexUser: (state, payload) => state.index_user = payload,
        setIndexPhoto: (state, payload) => state.index_photo = payload,
        clean: state => {
            state.users = []
            state.search = defaultSearch()
            state.filterOn = false
            state.counter = 0
            state.index_user = 0
            state.index_photo = 0
        },
        cleanUser: state => state.user = null,
        // Filters
        setSearch: (state, payload) => state.search = payload,
        setSearchName: (state, payload) => state.search.name = payload,
        setSearchAgeRange: (state, payload) => state.search.age_range = payload,
        setSearchDistance: (state, payload) => state.search.distance = payload,
        setSearchSearchFor: (state, payload) => state.search.search_for = payload,
        setSearchWithPhotos: (state, payload) => state.search.with_photos = payload,
        setSearchNewMembers: (state, payload) => state.search.new_members = payload,
        setSearchFeaturedMembers: (state, payload) => state.search.featured_member = payload,
        setSearchBodyType: (state, payload) => state.search.body_type = payload,
        setSearchSearchLimits: (state, payload) => state.search.search_limits = payload,
        cleanSearch: state => state.search = defaultSearch()
    },
    getters: {
        getSideBarOn: state => state.up,
        getUsers: state => state.users,
        getLoading: state => state.loading,
        getUsersCount: state => state.users.length,
        hasUsers: state => { if (state.users.length != 0) { return true } else { return false } },
        getUser: state => state.user,
        hasUser: state => { if (state.user != null) { return true } else { return false } },
        getFilterOn: state => state.filterOn,
        getIndexUser: state => state.index_user,
        getIndexPhoto: state => state.index_photo,
        getUserById: state => id_user => {
            let index = findIndex(state.users, o => o.id == id_user)
            if (index != -1) { return state.users[index] } else { return null }
        },
        getUserIdByIndex: state => state.users[state.index_user],
        getUserPhotoByIndex: state => state.user.photos[state.index_photo],
        getUserPhotos: state => state.user.photos,
        getUserPhotosCount: state => state.user.photos.length,
        // Filters
        getSearch: state => state.search,
        getSearchName: state => state.search.name,
        getSearchAgeRange: state => state.search.age_range,
        getSearchDistance: state => state.search.distance,
        getSearchSearchFor: state => state.search.search_for,
        getSearchWithPhotos: state => state.search.with_photos,
        getSearchNewMembers: state => state.search.new_members,
        getSearchFeaturedMembers: state => state.search.featured_member,
        getSearchBodyType: state => state.search.body_type,
        getSearchSearchLimits: state => state.search.search_limits
    },
    actions: {
        getUsersSearch: ({ commit, getters }) => {
            commit('setLoading', true)
            return window.axios.post('/search/get', getters.getSearch).then(response => {
                commit('setUsers', response.data)
                commit('setLoading', false)
                commit('setIndexUser', 0)
                commit('setIndexPhoto', 0)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setLoading', false)
                throw error
            })
        },
        getUser: ({ commit }, id_user) => {
            commit('setLoading', true)
            return window.axios.get('/search/get/' + id_user).then(response => {
                commit('setUser', response.data)
                commit('setIndexPhoto', 0)
                commit('setLoading', false)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setLoading', false)
                throw error
            })
        },
        getUsersSearchByName: ({ commit }, name) => {
            commit('setLoading', true)
            return window.axios.post('/search/get/users/name', {
                name: name
            }).then(response => {
                commit('setUsers', response.data)
                commit('setLoading', false)
                commit('setIndexUser', 0)
                commit('setIndexPhoto', 0)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setLoading', false)
                throw error
            })
        }
    }
}
