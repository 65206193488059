import {
    findIndex,
    filter,
    map,
    omit,
    reduce,
    keys,
    forEach,
} from "lodash-es";
import Vue from "vue";
import { v4 as uuidv4 } from "uuid";
import { events } from "../../messages-events";
import dayjs from "dayjs";
export default {
    namespaced: true,
    state: {
        id_conversation: null,
        user_to: null,
        room: "",
        messages: [],
        messages_pagination: null,
        loading: false,
        textLoading: false,
        text_box: "",
        search_bar: "",
        conversations: [],
        conversations_pagination: null,
        loadingConversations: false,
        conversations_init: false,
        conversation_has_loaded: false,
        quote: null,
        mini_chat_conversations: [],
        cancelToken: null, // Cancel fetchMessage request if there is another coming up
        searchedMessage: "",
        messagesResultFound: [],
        totalMessagesFound: 0,
        indexSearchMessage: 0,
        is_user_typing: false,
    },
    mutations: {
        setIdConversation: (state, payload) =>
            (state.id_conversation = payload),
        setUserTo: (state, payload) => (state.user_to = payload),
        setOnlineUserTo: (state, payload) => {
            state.user_to.online = payload.online;
            state.user_to.last_online_at = payload.last_online_at;
        },
        setRoom: (state, payload) => (state.room = payload),
        addMessages: (state, payload) =>
            forEach(payload, (p) => {
                state.messages.push(p);
            }),
        setMessages: (state, payload) =>
            (state.messages = payload.reverse().concat(state.messages)),
        cleanMessages: (state) => (state.messages = []),
        setMessagesPagination: (state, payload) =>
            (state.messages_pagination = omit(payload, ["results"])),
        setLoading: (state, payload) => (state.loading = payload),
        setTextLoading: (state, payload) => (state.textLoading = payload),
        addMessage: (state, payload) => state.messages.push(payload),
        setTextBox: (state, payload) => (state.text_box = payload),
        setQuote: (state, payload) => (state.quote = payload),
        setSearchBar: (state, payload) => (state.search_bar = payload),
        setReadMesssages: (state) => {
            for (let i = 0; i < state.messages.length; i++) {
                if (state.messages[i].id_user === state.user_to.id) {
                    state.messages[i].read = 1;
                }
            }
        },
        setReadMesssagesTo: (state) => {
            for (let i = 0; i < state.messages.length; i++) {
                if (state.messages[i].id_user !== state.user_to.id) {
                    state.messages[i].read = 1;
                }
            }
        },
        deleteMessage: (state, payload) =>
            state.messages.splice(
                findIndex(state.messages, (o) => o.id === payload.id),
                1
            ),
        // Conversations
        setConversations: (state, payload) => forEach(payload, p => {
            const index = findIndex(state.conversations, (o) => o._id === p._id);
            if (index == -1) {
                state.conversations.push(p);
            }
        }),
        // setConversations: (state, payload) => (state.conversations = payload.concat),
        setOnlineConversationsUserTo: (state, payload) => {
            const index = findIndex(
                state.conversations,
                (o) => o.user_to.id === payload.id
            );
            if (index > -1) {
                state.conversations[index].user_to.online = payload.online;
            }
        },
        setConversationsPagination: (state, payload) =>
            (state.conversations_pagination = omit(payload, ["results"])),
        setLoadingConversations: (state, payload) =>
            (state.loadingChats = payload),
        setConversationsInit: (state, payload) =>
            (state.conversations_init = payload),
        setConversationHasLoaded: (state, payload) =>
            (state.conversation_has_loaded = payload),
        addConversation: (state, payload) => state.conversations.push(payload),
        addOrUpdateConversation: (state, payload) => {
            let index = findIndex(state.conversations, (o) => {
                return o._id === payload._id;
            });
            if (index !== -1) {
                Vue.set(state.conversations, index, payload);
            } else {
                state.conversations.push(payload);
            }
        },
        updateConversationUnread: (state, payload) => {
            let index = findIndex(state.conversations, (o) => {
                return o.id_user_to === payload;
            });
            if (index !== -1) {
                state.conversations[index].unread_count = 0;
            }
        },
        removeConversation: (state, payload) => {
            let index = findIndex(state.conversations, (o) => {
                return o._id === payload.id;
            });
            if (index !== -1) {
                state.conversations.splice(index, 1);
            }
        },
        cleanConversations: (state) => {
            state.conversations = [];
            state.conversations_pagination = null;
            state.loadingConversations = false;
            state.conversations_init = false;
            state.conversation_has_loaded = false;
        },
        riseConversation: (state) => {
            let index = findIndex(state.conversations, (o) => {
                return o._id === state.id_conversation;
            });
            if (index !== -1) {
                state.conversations[index].updatedAt = new Date().toISOString();
            } else {
                console.log("conversation not found");
            }
        },
        setLastMessageConversation: (state, payload) => {
            let index = findIndex(state.conversations, (o) => {
                return o._id === state.id_conversation;
            });
            if (index !== -1) {
                state.conversations[index].last_message = payload;
            } else {
                console.log(
                    "conversation not found setLastMessageConversation"
                );
            }
        },
        setCancelToken: (state, payload) => (state.cancelToken = payload),
        setSearchedMessage: (state, payload) =>
            (state.searchedMessage = payload),
        setMessagesResultFound: (state, payload) =>
            (state.messagesResultFound = payload),
        setTotalMessagesFound: (state, payload) =>
            (state.totalMessagesFound = payload),
        setIndexSearchMessage: (state, payload) =>
            (state.indexSearchMessage = payload),
        setIsUserTyping: (state, payload) => {
            state.is_user_typing = payload;
        },
    },
    getters: {
        getIdConversation: (state) => state.id_conversation,
        getUserTo: (state) => state.user_to,
        getRoom: (state) => state.room,
        getMessages: (state) => state.messages,
        getMessagesPagination: (state) => state.messages_pagination,
        getLoading: (state) => state.loading,
        hasMessages: (state) => {
            if (state.messages.length !== 0) {
                return true;
            } else {
                return false;
            }
        },
        getTextLoading: (state) => state.textLoading,
        getTextBox: (state) => state.text_box,
        getQuote: (state) => state.quote,
        getSearchBar: (state) => state.search_bar,
        getUnreadMessages: (state) => filter(state.messages, { read: false }),
        getMessagesGroupedByDay: (state) => {
            const groups = reduce(
                state.messages,
                (result, message) => {
                    const date = dayjs(message.createdAt)
                        .startOf("day")
                        .format()
                        .split("T")[0];
                    if (!result[date]) {
                        result[date] = [];
                    }
                    result[date].push(message);
                    return result;
                },
                {}
            );

            return map(keys(groups), (date) => {
                return {
                    date,
                    messages: groups[date],
                };
            });
        },
        // Conversations
        getConversations: (state) =>
            state.conversations.sort((x, y) => {
                return new Date(y.updatedAt) - new Date(x.updatedAt);
            }),
        getConversationsPagination: (state) => state.conversations_pagination,
        getLoadingConversations: (state) => state.loadingConversations,
        getConversationsInit: (state) => state.conversations_init,
        getConversationHasLoaded: (state) => state.conversation_has_loaded,
        getConversationsByLimit: (state, getters) => (size) =>
            getters.getConversations.slice(0, size),
        hasConversations: (state) => {
            if (state.conversations.length !== 0) {
                return true;
            } else {
                return false;
            }
        },
        getCanceltoken: (state) => state.cancelToken,
        getSearchedMessage: (state) => state.searchedMessage,
        getTotalMessagesFound: (state) => state.totalMessagesFound,
        getMessagesFound: (state) => state.messagesResultFound,
        getIndexSearchMessage: (state) => state.indexSearchMessage,
        getIsUserTyping: (state) => state.is_user_typing,
    },
    actions: {
        searchMessage: ({ commit, getters }) => {
            commit("setMessagesResultFound", []);
            commit("setTotalMessagesFound", 0);
            let route = "/chat/search-new";
            return window.axios
                .get(route, {
                    params: {
                        room: getters.getRoom,
                        search: getters.getSearchedMessage,
                    },
                })
                .then((response) => {
                    commit("setMessagesResultFound", response.data.messages);
                    commit("setTotalMessagesFound", response.data.total);
                    return response.data;
                })
                .catch((error) => {
                    console.error(error);
                    commit("setLoading", false);
                    throw error;
                });
        },
        fetchMessages: ({ commit, getters, dispatch }, nextPage) => {
            // Check if there are any previous pending requests
            if (getters.getCanceltoken != null) {
                getters.getCanceltoken.cancel(
                    "Operation canceled due to new request."
                );
            }

            // Save the cancel token for the current request
            commit("setCancelToken", window.axios.CancelToken.source());

            commit("setLoading", true);
            let route = `${import.meta.env.VITE_MESSAGING_API}/api/message/${getters["getRoom"]}`;
            let page = 1;
            let limit = 20;
            if (nextPage != null) {
                page = nextPage;
            }
            return window.axios
                .get(route, {
                    params: {
                        limit,
                        page,
                    },
                    cancelToken: getters.getCanceltoken.token,
                })
                .then((response) => {
                    commit("setMessages", response.data.results);
                    commit("setMessagesPagination", response.data);
                    commit("setLoading", false);
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                    commit("setLoading", false);
                    throw error;
                });
        },
        addMessage: ({ commit, getters, rootState }) => {
            if (window.app.$socket.readyState !== 1) {
                throw new Error(
                    "Ocurrio un error, Revisa tu conexion a internet"
                );
            }
            commit("setTextLoading", true);
            const message = {
                action: events.SEND_MESSAGE,
                id: uuidv4(),
                message: getters.getTextBox,
                id_user: rootState.components.user_data.id,
                id_user_to: getters.getUserTo.id,
                room: getters.getRoom,
                quote: getters.getQuote,
                type: "message",
                user_from_type: rootState.components.user_data.type,
                id_conversation: getters.getIdConversation,
            };
            const data = JSON.stringify(message);
            const messageWithQuote = { ...message };
            let typeMessage = messageWithQuote.type.trim().toLowerCase();
            let quoteMessage = null;
            if (messageWithQuote.quote != null) {
                typeMessage = "quote";
                if (messageWithQuote.quote.type === "image") {
                    typeMessage = "quote_image";
                }
                quoteMessage = messageWithQuote.quote.message;
            }
            messageWithQuote["quote"] = quoteMessage;
            messageWithQuote["type"] = typeMessage;
            if (window.app.$socket.readyState === 1) {
                window.app.$socket.send(data);
                commit("addMessage", messageWithQuote);
                commit("setTextLoading", false);
                commit("setTextBox", "");
                commit("setQuote", null);
                commit("riseConversation");
                commit("setLastMessageConversation", messageWithQuote.message);
            }
            // return window.axios
            //     .post("/chat/message", {
            //         message: getters.getTextBox,
            //         id_user_to: getters.getUserTo.id,
            //         room: getters.getRoom,
            //         quote: getters.getQuote,
            //     })
            //     .then((response) => {
            //         commit("addMessage", response.data);
            //         commit("setTextLoading", false);
            //         commit("setTextBox", "");
            //         commit("setQuote", null);
            //         commit("riseConversation");
            //         return response.data;
            //     })
            //     .catch((error) => {
            //         console.log(error);
            //         commit("setTextLoading", false);
            //         throw error;
            //     });
        },
        getConversations: ({ commit, getters, rootGetters }, nextPage) => {
            commit("setLoadingConversations", true);
            let route = `${import.meta.env.VITE_MESSAGING_API}/api/${rootGetters["components/getUserData"].id}/conversations`;
            let page = 1;
            if (nextPage != null) {
                page = nextPage;
            }
            return window.axios
                .get(route, {
                    params: {
                        search: getters.getSearchBar,
                        page,
                    },
                })
                .then((response) => {
                    commit("setConversations", response.data.results);
                    commit("setConversationsPagination", response.data);
                    commit("setLoadingConversations", false);
                    commit("setConversationHasLoaded", true);
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                    commit("setLoadingConversations", false);
                    throw error;
                });
        },
        getUserTo: ({ commit }, id) => {
            let route = `/profiles/user/${id}`;
            return window.axios.get(route).then((response) => {
                commit("setUserTo", response.data);
            });
        },
        getUserToByConversation: ({ commit, getters, rootGetters }) => {
            return window.axios
                .get(
                    `${import.meta.env.VITE_MESSAGING_API}/api/conversation/${getters["getIdConversation"]}`
                )
                .then((response) => {
                    if (
                        response.data.user.id ===
                        rootGetters["components/getUserData"].id
                    ) {
                        let route = `/profiles/user/${response.data.user_to.id}`;
                        return window.axios
                            .get(route)
                            .then((responseProfile) => {
                                commit("setUserTo", responseProfile.data);
                            })
                            .catch((error) => {
                                console.log(error);
                                throw error;
                            });
                    }
                    let route = `/profiles/user/${response.data.user.id}`;
                    return window.axios
                        .get(route)
                        .then((responseProfile) => {
                            commit("setUserTo", responseProfile.data);
                        })
                        .catch((error) => {
                            console.log(error);
                            throw error;
                        });
                })
                .catch((error) => {
                    console.log(error);
                    throw error;
                });
        },
        changeConversation: ({ commit }, conversation) => {
            let route = `/profiles/user/${conversation.user_to.id}`;
            return window.axios
                .get(route)
                .then((response) => {
                    commit("setUserTo", response.data);
                    commit("setRoom", conversation.room);
                    commit("cleanMessages");
                })
                .catch((error) => {
                    console.log(error);
                    throw error;
                });
        },
        setReadMessages: ({ commit, getters, rootGetters }) => {
            if (getters.getUnreadMessages.length === 0 && !getters.getUserTo) {
                return;
            }
            const message = {
                action: events.MESSAGE_READ,
                id_user_to: getters.getUserTo.id,
                id_user: rootGetters["components/getUserData"].id,
                room: getters.getRoom,
            };
            const data = JSON.stringify(message);
            if (window.app.$socket.readyState === 1) {
                window.app.$socket.send(data);
                commit("setReadMesssages");
                commit("updateConversationUnread", getters.getUserTo.id);
            }
            return;
            // return window.axios
            //     .post("/chat/read", {
            //         id_user_to: getters.getUserTo.id,
            //         room: getters.getRoom,
            //     })
            //     .then((response) => {
            //         commit("setReadMesssages");
            //         commit("updateConversationUnread", getters.getUserTo.id);
            //         return response.data;
            //     })
            //     .catch((error) => {
            //         console.log(error);
            //         throw error;
            //     });
        },
        deleteMessage: ({ commit }, id_message) => {
            commit("setLoading", true);
            return window.axios
                .delete(
                    `${import.meta.env.VITE_MESSAGING_API}/api/message/${id_message}`
                )
                .then((response) => {
                    if (response.data.success) {
                        commit("deleteMessage", response.data.data);
                        commit("setLoading", false);
                    }
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                    commit("setLoading", false);
                    throw error;
                });
        },
        createOrGetConversation: ({ commit, rootGetters }, id_user_to) => {
            return window.axios
                .post(`${import.meta.env.VITE_MESSAGING_API}/api/conversation`, {
                    id_user_to: id_user_to,
                    id_user: rootGetters["components/getUserData"].id,
                })
                .then((response) => {
                    commit("addOrUpdateConversation", response.data);
                    return response.data;
                })
                .catch((error) => {
                    console.error(error);
                    throw error;
                });
        },
        uploadImage: ({ commit, getters, rootGetters }, formData) => {
            formData.append("room", getters.getRoom);
            formData.append("id_user_to", getters.getUserTo.id);
            commit("setLoading", true);
            return window.axios
                .post("/chat/upload-image", formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                })
                .then((response) => {
                    const message = {
                        action: events.SEND_MESSAGE,
                        id: uuidv4(),
                        message: response.data.path,
                        id_user: rootGetters["components/getUserData"].id,
                        id_user_to: getters.getUserTo.id,
                        room: getters.getRoom,
                        quote: getters.getQuote,
                        type: response.data.type,
                        user_from_type:
                            rootGetters["components/getUserData"].type,
                        id_conversation: getters.getIdConversation,
                    };
                    const data = JSON.stringify(message);
                    if (window.app.$socket.readyState === 1) {
                        window.app.$socket.send(data);
                        commit("addMessage", message);
                    }
                    commit("setLoading", false);
                    return response;
                })
                .catch((error) => {
                    console.log(error.message);
                    commit("setLoading", false);
                    throw error;
                });
        },
        deleteConversations: ({ commit, getters }) => {
            return window.axios
                .post("/chat/conversation/delete", {
                    id_user_to: getters.getUserTo.id,
                    room: getters.getRoom,
                })
                .then((response) => {
                    commit("removeConversation", response.data.conversation);
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                    throw error;
                });
        },
        setMessageForQuote: ({ commit, getters }, id_message) => {
            let index = findIndex(getters.getMessages, (o) => {
                return o.id === id_message;
            });
            if (index !== -1) {
                commit("setQuote", {
                    type: getters.getMessages[index].type,
                    message: getters.getMessages[index].message,
                });
            }
        },
        sendToQueue: ({ getters }) => {
            return window.axios
                .post("chat/queue-chat", {
                    id_user_to: getters.getUserTo.id,
                    room: getters.getRoom,
                })
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.error(error);
                    throw error;
                });
        },
    },
};
