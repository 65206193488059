const Base = () => import('./base.vue')
const Index = () => import('./index.vue');
const Preload = () => import('./preload.vue');

export default [
    {
        path: "/chat",
        component: Base, // No cambiar a otra base
        name: "Chat",
        redirect: "/chat/index",
        children: [
            {
                name: "Chat Conversation",
                path: "index",
                component: Preload,
                meta: {
                    requireBeApproved: true,
                    notTimeOut: true,
                    notSuspendedUser: true,
                },
            },
            {
                name: "Chat Conversation With User",
                path: "conversation/:room/:id",
                component: Index,
                meta: {
                    requireBeApproved: true,
                    notTimeOut: true,
                    notSuspendedUser: true,
                },
            },
        ],
    },
];
