import Pusher from "pusher-js";
import Echo from "laravel-echo";
import config from './config'

window.pusher = new Pusher(config.pusher.key, {
    cluster: config.pusher.cluster,
});

window.echo = new Echo({
    broadcaster: "pusher",
    cluster: config.pusher.cluster,
    encrypted: true,
    key: config.pusher.key,
    authorizer: (channel, _options) => {
        return {
            authorize: (socketId, callback) => {
                axios.post("/broadcasting/auth", {
                    socket_id: socketId,
                    channel_name: channel.name
                }).then((response) => {
                    callback(false, response.data)
                }).catch((error) => {
                    callback(true, error)
                });
            }
        }
    }
});

window.pusher.connection.bind('connected', () => {
    window.axios.defaults.headers.common['X-Socket-Id'] = window.pusher.connection.socket_id
})
