export const notifications = {
    methods: {
        handleErrors(error) {
            if (error.response.data.message) { this.errorMessage(error.response.data.message) }
            else { this.errorMessage(this.$t('inputs.notifications.server')) }
        },
        okMessage(msg, title = 'Ok') {
            this.$notify({
                group: 'global',
                title: title,
                text: msg,
                type: 'success'
            })
        },
        errorMessage(msg, title = 'Error') {
            this.$notify({
                group: 'global',
                title: title,
                text: msg,
                type: 'error'
            })
        }
    }
}