import { findIndex, filter, some, omit, concat, uniqBy } from "lodash-es";
import { paginate, isDateSameOrAfterNow, setTheme } from "../helpers";

export default {
    namespaced: true,
    state: {
        windowSize: {
            innerWidth: window.innerWidth,
            innerHeight: window.innerHeight,
        },
        module_title: "",
        user_data: null,
        token: localStorage.getItem("access_token") || null,
        loading: false,
        timeout: false,
        suspended: false,
        genders_options: [],
        search_for_options: [],
        search_limits_options: [],
        relation_options: [],
        sexual_orientation_options: [],
        body_type_options: [],
        i_live_with_options: [],
        eyes_color_options: [],
        hair_color_options: [],
        drink_options: [],
        tobacco_options: [],
        reports_options: [],
        chat: false,
        membership_types: null,
        credit_packs: null,
        app_languages_options: [
            { value: "en", text: "English" },
            { value: "es", text: "Spanish" },
            { value: "pt", text: "Portuguese" },
        ],
        favorites_users: [],
        blocked_users: [],
        initFinished: false,
        online_users: [],
        onlineUsersPagination: {
            next_cursor: null,
            next_page_url: null,
            prev_cursor: null,
            prev_page_url: null,
            path: "",
            per_page: 0,
        },
        updated_conversations_on: false,
        // responsive
        sidebar_on: true,
        topbar_on: false,
        aside_on: true,
        drop: true,
        // old
        toggle: false,
        expanded: true,
        love: true,
        notification: true,
        // pwa
        pwa_ready_install: false,
        pwa_install_prompt_event: null,
        countriesOptions: [],
        theme: 'system',
    },
    mutations: {
        setWindowSize: (state, payload) => (state.windowSize = payload),
        setModuleTitle: (state, payload) => (state.module_title = payload),
        setToken: (state, payload) => (state.token = payload),
        setLoading: (state, payload) => (state.loading = payload),
        setUserData: (state, payload) => (state.user_data = payload),
        setUserDataAvatar: (state, payload) =>
            (state.user_data.avatar = payload),
        setUserDataFullName: (state, payload) =>
            (state.user_data.full_name = payload),
        setUserGender: (state, payload) => (state.user_data.gender = payload),
        setTimeOut: (state, payload) => {
            if (payload.last_membership.expired) {
                state.timeout = payload.timeout;
            }
        },
        setSuspended: (state, payload) => (state.suspended = payload),
        destroyToken: (state) => {
            localStorage.removeItem("access_token");
            state.token = null;
        },
        setAppOptions: (state, payload) => {
            state.genders_options = payload.genders;
            state.search_for_options = payload.search_for;
            state.search_limits_options = payload.search_limits;
            state.relation_options = payload.relation;
            state.sexual_orientation_options = payload.sexual_orientation;
            state.body_type_options = payload.body_type;
            state.i_live_with_options = payload.i_live_with;
            state.eyes_color_options = payload.eyes_color;
            state.hair_color_options = payload.hair_color;
            state.drink_options = payload.drink;
            state.tobacco_options = payload.tobacco;
            state.reports_options = payload.reports;
            state.membership_types = payload.membership_types;
            state.credit_packs = payload.credit_packs;
        },
        setInitFinished: (state, payload) => (state.initFinished = payload),
        setFavoriteUsers: (state, payload) => (state.favorites_users = payload),
        setOnlineFavoriteUsers: (state, payload) => {
            const index = findIndex(
                state.favorites_users,
                (o) => o.user_favorite.id === payload.id
            );
            if (index > -1) {
                state.favorites_users[index].user_favorite.online =
                    payload.online;
            }
        },
        addFavorite: (state, payload) => state.favorites_users.unshift(payload),
        deleteFavorite: (state, payload) => {
            let index = findIndex(
                state.favorites_users,
                (o) => o.id === payload.id
            );
            if (index !== -1) {
                state.favorites_users.splice(index, 1);
            }
        },
        setBlockedUsers: (state, payload) => (state.blocked_users = payload),
        blockUser: (state, payload) => state.blocked_users.unshift(payload),
        unblockUser: (state, payload) => {
            let index = findIndex(
                state.blocked_users,
                (o) => o.id === payload.id
            );
            if (index !== -1) {
                state.blocked_users.splice(index, 1);
            }
        },
        unblockByIdUser: (state, payload) => {
            let index = findIndex(
                state.blocked_users,
                (o) => o.id_user_blocked === payload
            );
            if (index !== -1) {
                state.blocked_users.splice(index, 1);
            }
        },
        updateUserCredits: (state, payload) =>
            (state.user_data.credits = payload),
        updateUserMembership: (state, payload) =>
            (state.user_data.last_membership = payload),
        // Online Presence Channel
        setUsersOnline: (state, payload) => {
            const validUsers = filter(payload, (p) => p.user_type !== "null");
            const usersExceptMe = filter(validUsers, (p) => p.id !== state.user_data.id);
            const usersFilteredByType = filter(usersExceptMe, (p) => {
                if (state.user_data.type === "normal") {
                    return (
                        p.type !== "normal" &&
                        p.type !== "cs"
                    );
                }
                if (state.user_data.type === "virtual") {
                    return (
                        p.type !== "cs" &&
                        p.type !== "virtual"
                    );
                }
            });
            const activeUsers = filter(usersFilteredByType, (p) => p.active);
            const visibleUsers = filter(activeUsers, (p) => p.visible);
            state.online_users = uniqBy(concat(state.online_users, visibleUsers), "id");
        },
        setUsersOnlinePagination: (state, payload) => {
            state.onlineUsersPagination = omit(payload, "data");
        },
        addUserOnline: (state, payload) => {
            const index = state.online_users.findIndex(
                (user) => user.id === payload.id
            );
            if (index !== -1) {
                return;
            }
            // check invalid user type
            if (payload.user_type === "null") {
                return;
            }

            // Expired Membership and 40 sec Timeout
            if (payload.membership_expired) {
                if (payload.timeout) {
                    return;
                }
            }

            // Check if user is 'normal' or 'cs'
            if (state.user_data.type === "normal") {
                if (payload.type !== "normal" && payload.type !== "cs") {
                    state.online_users.unshift(payload);
                }
            }
            if (state.user_data.type === "virtual") {
                if (
                    payload.type !== "cs" &&
                    payload.type !== "virtual" &&
                    payload.id !== state.user_data.id
                ) {
                    state.online_users.unshift(payload);
                }
            }
        },
        removeUserOnline: (state, payload) => {
            let index = findIndex(state.online_users, (o) => o.id === payload);
            if (index !== -1) {
                state.online_users.splice(index, 1);
            }
        },
        updateUserOnlineAvatar: (state, payload) => {
            let index = findIndex(
                state.online_users,
                (o) => o.id === payload.id
            );
            if (index !== -1) {
                state.online_users[index].avatar = payload.avatar;
            }
        },
        setUpdatedConversationsOn: (state, payload) =>
            (state.updated_conversations_on = payload),
        // responive
        setSideBarOn: (state, payload) => (state.sidebar_on = payload),
        setTopBarOn: (state, payload) => (state.topbar_on = payload),
        setAsideOn: (state, payload) => (state.aside_on = payload),
        // old
        chatToggle: (state, payload) => (state.chat = payload),
        loveToggle: (state, payload) => (state.love = payload),
        dropmenu: (state, payload) => (state.drop = payload),
        expandedButton: (state, payload) => (state.expanded = payload),
        toggleButton: (state, payload) => (state.toggle = payload),
        // pwa
        setReadyPWAInstall: (state, payload) =>
            (state.pwa_ready_install = payload),
        setPWAInstallPromptEvent: (state, payload) =>
            (state.pwa_install_prompt_event = payload),
        setCountriesOptions: (state, payload) =>
            (state.countriesOptions = payload),
        setTheme: (state, payload) => (state.theme = payload),
    },
    getters: {
        getWindowSize: (state) => state.windowSize,
        isWindowWidth: (state) => (size, compare) => {
            // compare = less || more
            if (compare === "less") {
                return state.windowSize.innerWidth <= size;
            } else {
                return state.windowSize.innerWidth >= size;
            }
        },
        isWindowWidthMoreThan: (state) => (size) => {
            return state.windowSize.innerWidth <= size;
        },
        getModuleTitle: (state) => state.module_title,
        isOnMobile: (state) => {
            return state.windowSize.innerWidth < 767;
        },
        getToken: (state) => state.token,
        getLoading: (state) => state.loading,
        getUserData: (state) => state.user_data,
        getUserDataId: (state) => state.user_data.id,
        getUserDataCredits: (state) => state.user_data.credits,
        getUserDataMembership: (state) => state.user_data.last_membership,
        getUserDataMembershipExpired: (state) =>
            state.user_data.last_membership.expired,
        getUserApproved: (state) => state.user_data.approved,
        getSuspended: (state) => state.suspended,
        getUserDataSimple: (state) => {
            return {
                id: state.user_data.id,
                full_name: state.user_data.full_name,
                avatar: state.user_data.avatar,
            };
        },
        isUserMembershipExpired: (state) =>
            isDateSameOrAfterNow(state.user_data.last_membership.ends_at),
        getInitFinished: (state) => state.initFinished,
        getTimeOut: (state) => state.timeout,
        // Options
        getAppLanguageOptions: (state) => state.app_languages_options,
        getGendersOptions: (state) => state.genders_options,
        getSearchForOptions: (state) => state.search_for_options,
        getSearchLimitsOptions: (state) => state.search_limits_options,
        getRelationOptions: (state) => state.relation_options,
        getSexualOrientationOptions: (state) =>
            state.sexual_orientation_options,
        getBodyTypeOptions: (state) => state.body_type_options,
        getILiveWithOptions: (state) => state.i_live_with_options,
        getEyesColorOptions: (state) => state.eyes_color_options,
        getHairColorOptions: (state) => state.hair_color_options,
        getDrinkOptions: (state) => state.drink_options,
        getTobaccoOptions: (state) => state.tobacco_options,
        getReportsOptions: (state) => state.reports_options,
        getMembershipsTypes: (state) => state.membership_types,
        getCreditPacks: (state) => state.credit_packs,
        getFavoriteUsers: (state) => state.favorites_users,
        getFavoriteUsersByPage: (state) => (page, size) =>
            paginate(state.favorites_users, size, page),
        getFavoritesUsersCount: (state) => state.favorites_users.length,
        isFavorite: (state) => (id_user_favorite) => {
            let index = findIndex(
                state.favorites_users,
                (o) => o.id_user_favorite === id_user_favorite
            );
            return index !== -1;
        },
        isBlocked: (state) => (id_user_blocked) => {
            let index = findIndex(
                state.blocked_users,
                (o) => o.id_user_blocked === id_user_blocked
            );
            return index !== -1;
        },
        // Online Presence Channel
        isUserOnline: (state) => (id_user) =>
            some(state.online_users, ["id", id_user]),
        getUsersOnline: (state) => state.online_users,
        getOnlineUsersByPage: (state) => (page, size) =>
            paginate(state.online_users, size, page),
        getOnlineUsersPagination: (state) => state.onlineUsersPagination,
        getOnlineUsersCount: (state) => state.online_users.length,
        getUpdatedConversationsOn: (state) => state.updated_conversations_on,
        // responsive
        getSideBarOn: (state) => state.sidebar_on,
        getTopBarOn: (state) => state.topbar_on,
        getAsideOn: (state) => state.aside_on,
        // old
        chatToggle: (state) => state.chat,
        loveToggle: (state) => state.love,
        dropmenu: (state) => state.drop,
        expandedButton: (state) => state.expanded,
        toggleButton: (state) => state.toggle,
        // pwa
        getReadyPWAInstall: (state) => state.pwa_ready_install,
        getPWAInstallPromptEvent: (state) => state.pwa_install_prompt_event,
        getCountriesOptions: (state) => state.countriesOptions,
        getCountriesOptionsNames: (state) =>
            state.countriesOptions.map((country) => {
                return { text: country.name, value: country.id };
            }),
        isCsOrVirtual: (state) =>
            state.user_data.type === "cs" || state.user_data.type === "virtual",
        getTheme: (state) => state.theme,
    },
    actions: {
        /*appInitCheck: ({ commit }) => {
            commit('setLoading', true)
            return window.axios.get('/user').then(response => {
                commit('setUserData', response.data.user)
                commit('setAppOptions', response.data.options)
                commit('setFavoriteUsers', response.data.favorites)
                commit('setBlockedUsers', response.data.blocked)
                commit('setLoading', false)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setLoading', false)
                throw error
            })
        },*/
        setInitialData: ({ commit }, data) => {
            commit("setUserData", data.user);
            commit("setAppOptions", data.options);
            commit("setFavoriteUsers", data.favorites);
            commit("setBlockedUsers", data.blocked);
            commit("setTimeOut", data.user);
            commit("setSuspended", data.suspended);
        },
        getCountriesOptions: ({ commit }) => {
            return window.axios
                .get("/countries")
                .then((response) => {
                    commit("setCountriesOptions", response.data);
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        toggleFavorite: ({ commit }, id_user_favorite) => {
            return window.axios
                .post("/favorites/toggle", {
                    id_user_favorite: id_user_favorite,
                })
                .then((response) => {
                    if (response.data.action === "created") {
                        commit("addFavorite", response.data.favorite);
                    } else {
                        commit("deleteFavorite", response.data.favorite);
                    }
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                    throw error;
                });
        },
        toggleBlocked: ({ commit }, id_user_blocked) => {
            return window.axios
                .post("/settings/blocked/toggle", {
                    id_user_blocked: id_user_blocked,
                })
                .then((response) => {
                    if (response.data.action === "blocked") {
                        commit("blockUser", response.data.blocked);
                    } else {
                        commit("unblockUser", response.data.blocked);
                    }
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                    throw error;
                });
        },
        logout: ({ commit }) => {
            commit("setLoading", true);
            return window.axios
                .post("/logout")
                .then((response) => {
                    commit("destroyToken");
                    commit("setLoading", false);
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                    commit("setLoading", false);
                    throw error;
                });
        },
        getUsersOnline: ({ commit }, cursor = null) => {
            let route = "profiles/online/get";
            if (cursor) {
                route = "profiles/online/get?cursor=" + cursor;
            }
            commit("setLoading", true);
            return window.axios
                .get(route)
                .then((response) => {
                    commit("setUsersOnline", response.data.data);
                    commit("setUsersOnlinePagination", response.data);
                    return response.data;
                })
                .catch((error) => {
                    console.error(`Error trying to get online users`, error);
                    commit("setLoading", false);
                    throw error;
                })
                .finally(() => {
                    commit("setLoading", false);
                });
        },
        setThemeOption: ({ commit }, value) => {
            setTheme(value);
            commit("setTheme", value);
        }
    },
};
