import { findIndex, forEach, omit } from 'lodash-es'

export default {
    namespaced: true,
    state: {
        profiles: [],
        pagination: null,
        loading: false,
        profile: null,
        photos: [],
        photos_pagination: null,
        photos_loading: false,
        id_photo_selected: null,
        photo: null,
        photo_loading: false,
        photo_comments: [],
        photo_comments_pagination: null,
        photo_comments_loading: false,
        comment_text: '',
        country: null,
    },
    mutations: {
        setProfiles: (state, payload) => state.profiles = payload,
        setPagination: (state, payload) => state.pagination = omit(payload, ['data']),
        setLoading: (state, payload) => state.loading = payload,
        setProfile: (state, payload) => state.profile = payload,
        setPhotos: (state, payload) => state.photos = payload,
        setPhotosPagination: (state, payload) => state.photos_pagination = omit(payload, ['data']),
        setPhotosLoading: (state, payload) => state.photos_loading = payload,
        updatePhotosCommentNumber: (state, payload) => {
            let index = findIndex(state.photos, o => o.id == payload.id_photo)
            if (index != -1) { state.photos[index].comments_count++ }
        },
        setIdPhotoSelected: (state, payload) => state.id_photo_selected = payload,
        setPhoto: (state, payload) => state.photo = payload,
        setPhotoLoading: (state, payload) => state.photo_loading = payload,
        cleanProfileAndPhotos: state => {
            state.profile = null
            state.photos = []
        },
        addLike: (state, payload) => state.photo.likes.push(payload),
        removeLike: (state, payload) => state.photo.likes = [],
        setPhotoComments: (state, payload) => forEach(payload, p => { state.photo_comments.push(p) }),
        setPhotoCommentsPagination: (state, payload) => state.photo_comments_pagination = omit(payload, ['data']),
        setPhotoCommentsLoading: (state, payload) => state.photo_comments_loading = payload,
        setCommentText: (state, payload) => state.comment_text = payload,
        addPhotoComment: (state, payload) => state.photo_comments.unshift(payload),
        resetPhotoDataModal: state => {
            state.photo = null
            state.id_photo_selected = null
            state.photo_comments = []
            state.photo_comments_pagination = null
            state.comment_text = ''
        },
        setCountry: (state, payload) => state.country = payload
    },
    getters: {
        getProfiles: state => state.profiles,
        getPagination: state => state.pagination,
        getLoading: state => state.loading,
        getProfile: state => state.profile,
        getProfileById: state => id_profile => {
            let index = findIndex(state.profiles, o => o.id == id_profile)
            if (index != -1) { return state.profiles[index] } else { return null }
        },
        getPhotos: state => state.photos,
        getPhotosPagination: state => state.photos_pagination,
        getPhotosLoading: state => state.photos_loading,
        getProfileInterest: state => state.profile.interests,
        getIdPhotoSelected: state => state.id_photo_selected,
        getPhoto: state => state.photo,
        getPhotoLoading: state => state.photo_loading,
        getLikeFromPhoto: state => { if (state.photo.likes.length != 0) { return true } else { return false } },
        getPhotoComments: state => state.photo_comments.slice().reverse(),
        getPhotoCommentsPagination: state => state.photo_comments_pagination,
        getPhotoCommentsLoading: state => state.photo_comments_loading,
        getCommentText: state => state.comment_text,
        getCountry: state => state.country,
    },
    actions: {
        getProfiles: ({ commit }, param) => {
            commit('setLoading', true)
            let route = '/profiles/get'
            if (param.url != null) { route = param.url }
            const params = new URLSearchParams();
            params.append('featured', param.featured)
            return window.axios.get(route, {params}).then(response => {
                commit('setProfiles', response.data.data)
                commit('setPagination', response.data)
                commit('setLoading', false)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setLoading', false)
                throw error
            })
        },
        getProfile: ({ commit }, id_user) => {
            commit('setLoading', true)
            return window.axios.get('/profiles/get/' + id_user).then(response => {
                commit('setProfile', response.data)
                commit('setLoading', false)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setLoading', false)
                throw error
            })
        },
        getLocationInformation: ({commit} , id_user) => {
            commit('setLoading', true)
            const params = new URLSearchParams()
            params.append('user_id', id_user)
            return window.axios.get('profile/location-information/get', {params}).then(response => {
                if (response.data.country == null) {
                    commit('setCountry', '')
                    return
                }
                commit('setCountry', response.data.country.name)
            }).catch(error => {
                console.error(error)
                commit('setLoading', false)
                throw error
            }).finally(() => {
                commit('setLoading', false)
            })
        },
        getProfilePhotos: ({ commit, getters }, url) => {
            commit('setPhotosLoading', true)
            let route = '/profiles/photos/' + getters.getProfile.id
            if (url != null) { route = url }
            return window.axios.get(route).then(response => {
                commit('setPhotos', response.data.data)
                commit('setPhotosPagination', response.data)
                commit('setPhotosLoading', false)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setPhotosLoading', false)
                throw error
            })
        },
        getPhoto: ({ commit, getters }) => {
            commit('setPhotoLoading', true)
            return window.axios.get('/profiles/photo/' + getters.getIdPhotoSelected).then(response => {
                commit('setPhoto', response.data)
                commit('setPhotoLoading', false)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setPhotoLoading', false)
                throw error
            })
        },
        getPhotoComments: ({ commit, getters }, url) => {
            commit('setPhotoCommentsLoading', true)
            let route = '/profiles/photo/comments/' + getters.getIdPhotoSelected
            if (url != null) { route = url }
            return window.axios.get(route).then(response => {
                commit('setPhotoComments', response.data.data)
                commit('setPhotoCommentsPagination', response.data)
                commit('setPhotoCommentsLoading', false)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setPhotoCommentsLoading', false)
                throw error
            })
        },
        sendComment: ({ commit, getters }) => {
            commit('setPhotoLoading', true)
            return window.axios.post('/profiles/photo/comment', {
                comment: getters.getCommentText,
                id_photo: getters.getIdPhotoSelected
            }).then(response => {
                commit('setCommentText', '')
                commit('addPhotoComment', response.data)
                commit('updatePhotosCommentNumber', response.data)
                commit('setPhotoLoading', false)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setPhotoLoading', false)
                throw error
            })
        },
        toggleLikePhoto: ({ commit, getters }) => {
            commit('setPhotoLoading', true)
            return window.axios.post('/profiles/photo/like', {
                id_photo: getters.getIdPhotoSelected
            }).then(response => {
                if (response.data.action == 'created') {
                    commit('addLike', response.data.like)
                } else {
                    commit('removeLike')
                }
                commit('setPhotoLoading', false)
                return response.data
            }).catch(error => {
                console.log(error)
                commit('setPhotoLoading', false)
                throw error
            })
        }
    }
}
